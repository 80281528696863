<div class="customs-container">
	<!-- criterias left -->
	<form class="criteria-container">
		<!-- forme -->
		<div class="criteria">
			<div class="criteria-content">
				<div class="criteria-title">
					Forme
				</div>
				<div class="criteria-text">
					Choisissez la forme de votre boitier.
					<div class="criteria-choices" (change)="setOptions()">
						<!-- rectangle -->
						<div [ngClass]="{'criteria-choice': true, selected: (form=='R')}">
							<input type="radio" [(ngModel)]="form" id="form-R" name="form" value="R"/>
							<label for="form-R" class="fa-light fa-sidebar fa-rotate-90 fa-2x"></label>
						</div>

						<!-- carré -->
						<div [ngClass]="{'criteria-choice': true, selected: (form=='C')}">
							<input type="radio" [(ngModel)]="form" id="form-C" name="form" value="C"/>
							<label for="form-C" class="fa-light fa-sidebar fa-2x"></label>
						</div>

						<!-- hexagonal -->
						<div [ngClass]="{'criteria-choice': true, selected: (form=='H')}">
							<input type="radio" [(ngModel)]="form" id="form-H" name="form" value="H"/>
							<label for="form-H" class="fa-regular fa-hexagon-image fa-2x"></label>
						</div>
					</div>
				</div>
			</div>
			<div>
				<div class="criteria-icon">
					<!-- <i class="fa-solid fa-compass-drafting fa-2x fa-fw"></i> -->
					<i class="fa-duotone fa-hexagon-image fa-3x fa-fw"></i>
				</div>
			</div>
		</div>

		<!-- Orientation -->
		<div class="criteria">
			<div class="criteria-content">
				<div class="criteria-title">
					Etiquette
				</div>
				<div class="criteria-text">
					Choisissez la position de votre étiquette.
					<div class="criteria-choices">
						<!-- Top -->
						<div *ngIf="form=='R'" [ngClass]="{'criteria-choice': true, selected: (position=='H')}">
							<input type="radio" [(ngModel)]="position" id="pos-H" name="position" value="H"/>
							<label for="pos-H" class="fa-light fa-arrow-down-to-bracket fa-rotate-180 fa-2x"></label>
						</div>

						<!-- right -->
						<div *ngIf="form=='C' || form=='H'" [ngClass]="{'criteria-choice': true, selected: (position=='D')}">
							<input type="radio" [(ngModel)]="position" id="pos-D" name="position" value="D"/>
							<label for="pos-D" class="fa-light fa-arrow-down-to-bracket fa-rotate-270 fa-2x"></label>
						</div>

						<!-- bottom -->
						<div *ngIf="form=='R'" [ngClass]="{'criteria-choice': true, selected: (position=='B')}">
							<input type="radio" [(ngModel)]="position" id="pos-B" name="position" value="B"/>
							<label for="pos-B" class="fa-light fa-arrow-down-to-bracket fa-2x"></label>
						</div>

						<!-- left -->
						<div *ngIf="form=='C' || form=='H'" [ngClass]="{'criteria-choice': true, selected: (position=='G')}">
							<input type="radio" [(ngModel)]="position" id="pos-G" name="position" value="G"/>
							<label for="pos-G" class="fa-light fa-arrow-down-to-bracket fa-rotate-90 fa-2x"></label>
						</div>
					</div>
				</div>
			</div>
			<div>
				<div class="criteria-icon">
					<i class="fa-duotone fa-tags fa-3x fa-fw"></i>
				</div>
			</div>
		</div>

		<!-- Aimant -->
		<div class="criteria">
			<div class="criteria-content">
				<div class="criteria-title">
					Aimants
				</div>
				<div class="criteria-text">
					Désirez-vous des aimants ?
					<div class="criteria-choices">
						<!-- No Magnet -->
						<div [ngClass]="{'criteria-choice': true, selected: !magnet}">
							<input type="radio" [(ngModel)]="magnet" id="magnet-N" name="magnet" [value]="false"/>
							<label for="magnet-N" class="fa-duotone fa-bolt-slash fa-2x"></label>
						</div>

						<!-- Magnet -->
						<div [ngClass]="{'criteria-choice': true, selected: magnet}">
							<input type="radio" [(ngModel)]="magnet" id="magnet-O" name="magnet" [value]="true"/>
							<label for="magnet-O" class="fa-duotone fa-bolt-lightning fa-2x"></label>
						</div>
					</div>
				</div>
			</div>
			<div>
				<div class="criteria-icon">
					<!-- <i class="fa-solid fa-bolt fa-rotate-90"></i> -->
					<i class="fa-duotone fa-bolt"></i>
					<i class="fa-duotone fa-magnet fa-2x fa-rotate-270 fa-fw"></i>
				</div>
			</div>
		</div>
	</form>

	<div class="cube-wrap">
		<div [ngClass]="{cube: true, rotate: isRotate}">
			<div class="slab front">
				<img alt="Super-Grade's slab" [src]="getImage()"/>				
			</div>
			<div class="slab back">
				<img alt="Super-Grade's slab" [src]="getImageBack()"/>			
			</div>
			<div class="slab left"></div>
			<div class="slab right"></div>
		</div>
	</div>
</div>