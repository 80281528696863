import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { UIModule } from '../../core/ui/ui.module';
import { GradingComponent } from './grading/grading.component';
import { SectionModule } from '../section/section.module';
import { SlabComponent } from './slab/slab.component';
import { ReportComponent } from './report/report.component';
import { FiveDComponent } from './five-d/five-d.component';
import { NftComponent } from './nft/nft.component';
import { CgvComponent } from './cgv/cgv.component';

@NgModule({
  declarations: [
    HomeComponent,
  	GradingComponent,
  	SlabComponent,
  	ReportComponent,
  	FiveDComponent,
  	NftComponent,
  	CgvComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    UIModule,
    FormsModule,
    SectionModule
  ]
})
export class PageModule { }
