import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class ApiService{
	constructor(private http: HttpClient){}

	get<T>(route: string, params?: any){
		return this.http.get<T>(environment.urlApi+route, params)
			.pipe(
				map((result: any) => result.data)
			);
	}

	post<T>(route: string, data: any, params?: any){
		return this.http.post<T>(environment.urlApi+route, data, params)
			.pipe(
				map((result: any) => result.data)
			);
	}

	delete(route: string, params?: any){
		return this.http.delete(environment.urlApi+route, params)
			.pipe(
				map((result: any) => result.data)
			);
	}
}