import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UIModule } from './../../core/ui/ui.module';
import { SectionModule } from './../section/section.module';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';



@NgModule({
  declarations: [
  	RegisterComponent,
  	LoginComponent
  ],
  imports: [
    CommonModule,
    SectionModule,
    UIModule,
    RouterModule,
    FormsModule, 
    ReactiveFormsModule
  ],
  exports:[
    RegisterComponent,
    LoginComponent
  ]
})
export class UsersModule { }
