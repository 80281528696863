<section>
	<article class="nft-header">
		<header>
			<div class="nft-header-title">			
				<h1>Token NFT</h1>
				<span class="nft-header-subtitle">Le certificat numérique unique et sécurisé</span>
			</div>
		</header>
		<div>
			<img class="nft-header-img" src="assets/img/skin/hero-1.png" alt="Slab Super-Grade"/>
		</div>
	</article>
	<article class="pres-container">
		<header>
			<h2>Qu'est-ce qu'un token NFT</h2>
		</header>
		<div class="nft-criteria-container">
			<div class="nft-criteria">
				<div class="nft-criteria-icon">
					<sg-icon icon="fa-light fa-file-certificate" [css]="{margin: 'auto'}"></sg-icon>
				</div>
				<span class="nft-title">Cetificats d'authenticité : </span>un NFT constitue une preuve numérique de provenance et de propriété.
			</div>

			<div class="nft-criteria">
				<div class="nft-criteria-icon">
					<sg-icon icon="fa-light fa-circle-1" [css]="{margin: 'auto'}"></sg-icon>
				</div>
				<span class="nft-title">Unicité : </span>un NFT est unique et un certificat infalsifiable vient authentifier cette unicité.
			</div>

			<div class="nft-criteria">
				<div class="nft-criteria-icon">
					<sg-icon icon="fa-light fa-shield-keyhole" [css]="{margin: 'auto'}"></sg-icon>
				</div>
				<span class="nft-title">Sécurité : </span>un NFT créé ne peut être ni copié ni être falsifié.
			</div>
		</div>
	</article>
	<article class="pres-container">
		<header class="nft-why-header">
			<h2>Pourquoi lier une carte avec un NFT ?</h2>
			Pour certains, les NFT sont l'avenir de la propriété,<br/>
			une mode réservée aux collectionneurs.
		</header>
		<div class="nft-why-container">
			<div class="nft-why-wrap">
				<div class="nft-why-img">
					<sg-icon icon="fa-light fa-file-certificate" [css]="{margin: '10px', 'box-shadow': '1rem 1rem 2rem rgb(0 0 0 / 75%)'}"></sg-icon>
					<sg-icon icon="fa-light fa-user-secret" [css]="{margin: '10px', 'box-shadow': '1rem 1rem 2rem rgb(0 0 0 / 75%)'}"></sg-icon>
					<sg-icon icon="fa-light fa-repeat" [css]="{margin: '10px', 'box-shadow': '1rem 1rem 2rem rgb(0 0 0 / 75%)'}"></sg-icon>
				</div>
			</div>
			<div>
				<div class="nft-why-content">
					<sg-icon-reverse icon="fa-duotone fa-file-certificate" [css]="{'margin-right': '20px'}"></sg-icon-reverse>
					<div class="nft-why-text text-content">
						<h3>Cetificats d'authenticité</h3>
						Obtenez un token NFT pour constituer une preuve numérique de l'autehnticité de votre carte et de la propriété de votre carte.
					</div>
				</div>
				<div class="nft-why-content">
					<sg-icon-reverse icon="fa-duotone fa-user-secret" [css]="{'margin-right': '20px'}"></sg-icon-reverse>
					<div class="nft-why-text text-content">
						<h3>Anti Fraude</h3>
						Grâce à votre token NFT, plus de fraude possible ! Le token est unique, infalsifiable et sécurisé.
					</div>
				</div>
				<div class="nft-why-content">
					<sg-icon-reverse icon="fa-duotone fa-repeat" [css]="{'margin-right': '20px'}"></sg-icon-reverse>
					<div class="nft-why-text text-content">
						<h3>Transfert de propriété</h3>
						Lorsque votre carte change de propriétaire, vous pouvez transférer simplement votre Token NFT au nouvau pocesseur de la carte ou simplement détruire le certificat numérique.
					</div>
				</div>
			</div>
		</div>
	</article>
	<sg-start-grading></sg-start-grading>
</section>