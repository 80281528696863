import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, switchMap, filter, tap, pairwise } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { TypeCard, SetCard, Card, SetTranslation } from './../../../../models/card.model';
import { Lang } from './../../../../models/lang.model';
import { CardService } from './../../../../services/card.service';
import { LangService } from './../../../../services/lang.service';
import { CartService, CardCartOpt } from './../../../../services/cart.service';
import { CardPipe } from './../../../../pipes/pipe-card/card.pipe';
import { SetPipe } from './../../../../pipes/pipe-card/set.pipe';

@Component({
  selector: 'sg-search-card',
  templateUrl: './search-card.component.html',
  styleUrls: ['./search-card.component.css']
})
export class SearchCardComponent implements OnInit {
  @Input() title: string;
  @Input() option: CardCartOpt;
  @Output('addCard') cardSelected: EventEmitter<Card>;
  types: Array<TypeCard>=[];
  sets: Array<SetCard>=[];
  cards: Array<Card>=[];
  langs: Array<Lang>=[];
  loading: boolean=true;
  page: number=0;
  lengthCard: number=16;

  //form
  search: FormGroup;

  constructor(private cart: CartService, private cs: CardService, private ls: LangService, private fb: FormBuilder, private cpipe: CardPipe, private spipe: SetPipe) {
    this.cardSelected=new EventEmitter<Card>();

    //init formcontrol du formulaire
    this.initFormSearch();

    let globalVar: any=forkJoin({
      types: this.cs.getTypes(),
      langs: this.ls.getLangs()
    });

    globalVar.subscribe({
      next: (val: any) => {
        this.types=val.types;
        this.langs=val.langs;
      },
      complete: () => this.loading=false
    });
  }

  initFormSearch(){
    //formulaire
    this.search=this.fb.group({
      search: this.fb.control(''),
      type: this.fb.control('', [Validators.required]),
      lang: this.fb.control('', [Validators.required])
    });
  }

  ngOnInit(): void {
    //activated
    this.initActive();

    //search au key up
    this.search.valueChanges
      .pipe(
        debounceTime(400),
        filter(val => (val.search && val.search.length>=3)),
        //pairwise(),
        distinctUntilChanged(),
        tap(val => {
          this.loading=true;
          this.page=0;
        }),
        switchMap(val => this.getCards()),
        tap(() => this.loading=false)
      )
      .subscribe(cards => this.cards=cards);

    this.search.get('lang').valueChanges.subscribe(val => this.initActive());

  }

  getCards(start: number=0, length: number=16){
    return this.cs.search(this.search.get('search').value, this.search.get('type').value, this.search.get('lang').value, start, length);
  }

  initActive(){
    if(this.search.valid)
      this.search.controls['search'].enable();
    else
      this.search.controls['search'].disable();
  }

  changePage(page: number){
    this.loading=true;
    this.page=page-1;
    this.getCards(this.page*this.lengthCard, this.lengthCard)
      .pipe(
        tap(() => this.loading=false),
      )
      .subscribe(cards => this.cards=cards);
  }

  addCard(card: any){
    this.option.lang=this.search.controls['lang'].value;
    this.option.ident=card.card.ident;
    this.option.card=this.cpipe.transform(card.card, 'name', this.option.lang);
    this.option.set=this.spipe.transform(card.set, 'name', this.option.lang);
    this.option.numero=card.card.numero;
    this.cardSelected.emit(card.card);
  }

  addCustomCard(card: any){
    this.option.lang=this.search.controls['lang'].value;
    this.option.ident=null;
    this.option.card=card.name;
    this.option.set=card.set;
    this.option.numero=card.numero;
    this.cardSelected.emit(card.card);
  }
}
