<footer>
	<div class="footer-content">
		<div class="footer-part">
			<img class="footer-logo" src="assets/img/icon/logo-white.png" alt="Super-Grade" alt="logo Super-Grade"/>
			<div>
				Le grading fiable, intelligent et avec style ! Vivez de nouvelles expériences avec votre collection de carte.
			</div>
			<div class="social">
				<a href="" alt="Facebook Super-Grade"><i class="fa-brands fa-facebook fa-2x"></i></a>
				<a href="" alt="Instagram Super-Grade"><i class="fa-brands fa-instagram fa-2x"></i></a>
				<a href="" alt="YouTube Super-Grade"><i class="fa-brands fa-youtube fa-2x"></i></a>
			</div>
		</div>
		<div class="footer-part">
			<div class="footer-title">
				Contactez-Nous
			</div>
			<address>
				<div class="address-part">
					<div class="address-icon">
						<i class="fa-regular fa-map-location-dot fa-2x"></i>	
					</div>
					<div>
						15 rue du petit St-Dié<br/>
						88100, Saint-Dié-Des-Vosges
					</div>
				</div>
				<div class="address-part">
					<div class="address-icon">
						<i class="fa-duotone fa-mobile-button fa-2x"></i>
					</div>
					<div>
						06 69 91 91 73
					</div>
				</div>
				<div class="address-part">
					<div class="address-icon">
						<i class="fa-duotone fa-paper-plane fa-2x"></i>
					</div>
					<div>
						<a href="mailto:info@super-grade.com" title="e-mail Super-Grade">info@super-grade.com</a>
					</div>
				</div>
			</address>
		</div>
		<div class="footer-part">
			<div class="footer-title">
				Accès rapides
			</div>
			<ul class="footer-quick-access">
				<li><a href="" alt="Grading" [routerLink]="rs.getRouterLink('grading')" routerLinkActive="selected">Grading</a></li>
				<li><a href="" alt="Nos boitiers" [routerLink]="rs.getRouterLink('slab')" routerLinkActive="selected">Nos boitiers</a></li>
				<li><a href="" alt="Le report" [routerLink]="rs.getRouterLink('report')" routerLinkActive="selected">Le report</a></li>
				<li><a href="" alt="Vue 5D" [routerLink]="rs.getRouterLink('five-d')" routerLinkActive="selected">Vue 5D</a></li>
				<li><a href="" alt="NFT" [routerLink]="rs.getRouterLink('nft')" routerLinkActive="selected">NFT</a></li>
			</ul>
		</div>
	</div>
	<div class="right">
		Tout droit réservé. Développé par Super-Grade.
	</div>
</footer>