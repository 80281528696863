import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CardCartOpt, CartService } from './../../../services/cart.service';
import { RouterService } from './../../../services/router.service';

@Component({
  selector: 'sg-card-tab',
  templateUrl: './card-tab.component.html',
  styleUrls: ['./card-tab.component.css']
})
export class CardTabComponent implements OnInit {

  constructor(public cart: CartService, private router: Router, private rs: RouterService) { }

  ngOnInit(): void {
  }

  rmOption(i: number){
  	this.cart.rmCard(i);
  }

  nextStep(){
    if(!this.cart.isEmpty())
      this.router.navigate(this.rs.getRouterLink('grading.check-cart'));
  }
}
