<section>
	<sg-header title="Vue 5D"></sg-header>
	<article class="pres-container">
		<div class="fiveD-ecplain">
			<div>
				<sg-five-d-layers></sg-five-d-layers>
			</div>
			<div class="fiveD-content">
				<header>
					<h2>Vivez votre collection comme jamais !</h2>
				</header>
				Super-Grade vous propose de vivre une expérience incroyable avec une vue en plusieurs dimensions de vos carte de collection. Retrouvez tous les détails : micro rayure, choc, trace d'usure... qui rend unique chacune de vos cartes.

				<!-- Vue réelle -->
				<div class="fiveD-criterias box-separator">
					<div class="fiveD-criteria">
						<sg-icon-reverse icon="fa-duotone fa-solar-system"></sg-icon-reverse>
						<span class="fiveD-title">Vue 3D réelle</span>
					</div>

					<!-- Vue IR -->
					<div class="fiveD-criteria">
						<sg-icon-reverse icon="fa-duotone fa-satellite-dish fa-rotate-90"></sg-icon-reverse>
						<span class="fiveD-title">Vue Infra Rouge</span>
					</div>

					<!-- Vue UV -->
					<div class="fiveD-criteria">
						<sg-icon-reverse icon="fa-duotone fa-eclipse fa-rotate-90"></sg-icon-reverse>
						<span class="fiveD-title">Vue Ultra Violet</span>
					</div>

					<!-- Vue topographique -->
					<div class="fiveD-criteria">
						<sg-icon-reverse icon="fa-duotone fa-radar"></sg-icon-reverse>
						<span class="fiveD-title">Vue topographique</span>
					</div>
				</div>
			</div>
		</div>
	</article>
	<article class="pres-container">
		<div class="fiveD-layer">
			<div class="fiveD-img-container">			
				<img class="fiveD-layer-img" src="assets/img/skin/fived-reel.jpg" alt="3D vue réel"/>
			</div>
			<div class="fiveD-layer-content">
				<h2>3D vue réel</h2>
				La vue 3D réelle vous permet d'observer votre carte sous tous les angles, avec une pérécicion au 1000eme de millimètre. Cette vue correspond à une reconstruction 3D de votre carte avec des couleurs dans le spectre du visible.
			</div>
		</div>

		<div class="fiveD-layer">
			<div class="fiveD-img-container">			
				<img class="fiveD-layer-img" src="assets/img/skin/fived-uv.jpg" alt="3D vue réel"/>
			</div>
			<div class="fiveD-layer-content">
				<h2>3D vue Ultra Violet</h2>
				La vue 3D Ultra Violet vous permet d'observer votre carte sous tous les angles, avec une pérécicion au 1000eme de millimètre. Cette vue correspond à une reconstruction 3D de votre carte sous lumière Ultra Violet afin de mettre en évidence certains défauts.
			</div>
		</div>

		<div class="fiveD-layer">
			<div class="fiveD-img-container">			
				<img class="fiveD-layer-img" src="assets/img/skin/fived-ir.jpg" alt="3D vue réel"/>
			</div>
			<div class="fiveD-layer-content">
				<h2>3D vue Infra Rouge</h2>
				La vue 3D Infra Rouge vous permet d'observer votre carte sous tous les angles, avec une pérécicion au 1000eme de millimètre. Cette vue correspond à une reconstruction 3D de votre carte sous lumière Infra Rouge afin de mettre en évidence certains défauts.
			</div>
		</div>

		<div class="fiveD-layer">
			<div class="fiveD-img-container">			
				<img class="fiveD-layer-img" src="assets/img/skin/fived-topo.jpg" alt="3D vue réel"/>
			</div>
			<div class="fiveD-layer-content">
				<h2>3D vue Topographique</h2>
				La vue 3D Topographique vous permet d'observer votre carte sous tous les angles, avec une pérécicion au 1000eme de millimètre. Cette vue correspond à une reconstruction 3D des reliefs de votre carte afin de mettre en évidence certains défauts.
			</div>
		</div>
	</article>
	<sg-start-grading></sg-start-grading>
</section>