<form class="criterias-container">
	<div class="criteria">
		<div class="box-title">
			Forme
		</div>
		<div class="criteria-values" (change)="setOptions()">
			<div [ngClass]="{'criteria-value': true, selected: (option.slab.form=='R')}">
				<input type="radio" id="form-R" [(ngModel)]="option.slab.form" name="form" value="R"/>
				<label for="form-R" class="fa-light fa-sidebar fa-rotate-90 fa-2x"></label>
			</div>
			<div [ngClass]="{'criteria-value': true, selected: (option.slab.form=='C')}">
				<input type="radio" id="form-C" [(ngModel)]="option.slab.form" name="form" value="C"/>
				<label for="form-C" class="fa-light fa-sidebar fa-2x"></label>
			</div>
			<div [ngClass]="{'criteria-value': true, selected: (option.slab.form=='H')}">
				<input type="radio" id="form-H"[(ngModel)]="option.slab.form" name="form" value="H"/>
				<label for="form-H" class="fa-regular fa-hexagon-image fa-2x"></label>
			</div>
		</div>
	</div>
	<div class="criteria">
		<div class="box-title">
			Etiquette
		</div>
		<div class="criteria-values">
			<div *ngIf="option.slab.form=='R'" [ngClass]="{'criteria-value': true, selected: (option.slab.position=='H')}">
				<input type="radio" id="pos-H" [(ngModel)]="option.slab.position" name="pos" value="H"/>
				<label for="pos-H" class="fa-light fa-arrow-down-to-bracket fa-rotate-180 fa-2x"></label>
			</div>
			<div *ngIf="option.slab.form=='C' || option.slab.form=='H'" [ngClass]="{'criteria-value': true, selected: (option.slab.position=='D')}">
				<input type="radio" id="pos-D" [(ngModel)]="option.slab.position" name="pos" value="D"/>
				<label for="pos-D" class="fa-light fa-arrow-down-to-bracket fa-rotate-270 fa-2x"></label>
			</div>
			<div *ngIf="option.slab.form=='R'" [ngClass]="{'criteria-value': true, selected: (option.slab.position=='B')}">
				<input type="radio" id="pos-B" [(ngModel)]="option.slab.position" name="pos" value="B"/>
				<label for="pos-B" class="fa-light fa-arrow-down-to-bracket fa-2x"></label>
			</div>
			<div *ngIf="option.slab.form=='C' || option.slab.form=='H'" [ngClass]="{'criteria-value': true, selected: (option.slab.position=='G')}">
				<input type="radio" id="pos-G" [(ngModel)]="option.slab.position" name="pos" value="G"/>
				<label for="pos-G" class="fa-light fa-arrow-down-to-bracket fa-rotate-90 fa-2x"></label>
			</div>
		</div>
	</div>
	<div class="criteria">
		<div class="box-title">
			Aimants
		</div>
		<div class="criteria-values">
			<div [ngClass]="{'criteria-value': true, selected: option.slab.magnet}">
				<input type="radio" id="magnet-O" [(ngModel)]="option.slab.magnet" name="magnet" [value]="true"/>
				<label for="magnet-O" class="fa-duotone fa-bolt-lightning fa-2x"></label>
			</div>
			<div [ngClass]="{'criteria-value': true, selected: !option.slab.magnet}">
				<input type="radio" id="magnet-N" [(ngModel)]="option.slab.magnet" name="magnet" [value]="false"/>
				<label for="magnet-N" class="fa-duotone fa-bolt-slash fa-2x"></label>
			</div>
		</div>
	</div>
	<div class="criteria">
		<div class="box-title">
			5D
		</div>
		<div class="criteria-values">
			<div [ngClass]="{'criteria-value': true, selected: option.fiveD}">
				<input type="radio" id="fived-O" [(ngModel)]="option.fiveD" name="fived" [value]="true"/>
				<label for="fived-O" class="fa-light fa-cube fa-2x"></label>
			</div>
			<div [ngClass]="{'criteria-value': true, selected: !option.fiveD}">
				<input type="radio" id="fived-N" [(ngModel)]="option.fiveD" name="fived" [value]="false"/>
				<label for="fived-N" class="fa-light fa-empty-set fa-2x"></label>
			</div>
		</div>
	</div>
	<div class="criteria">
		<div class="box-title">
			NFT
		</div>
		<div class="criteria-values">
			<div [ngClass]="{'criteria-value': true, selected: option.nft}">
				<input type="radio" id="nft-O" [(ngModel)]="option.nft" name="nft" [value]="true"/>
				<label for="nft-O" class="fa-light fa-file-certificate fa-2x"></label>
			</div>
			<div [ngClass]="{'criteria-value': true, selected: !option.nft}">
				<input type="radio" id="nft-N" [(ngModel)]="option.nft" name="nft" [value]="false"/>
				<label for="nft-N" class="fa-light fa-empty-set fa-2x"></label>
			</div>
		</div>
	</div>
</form>