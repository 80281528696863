<div *ngIf="!isCustomCard">
	<div *ngIf="cards.length!=0" class="options-cards">
		<sg-button [reverse]="true" title="Affichage liste" classes="mini" (click)="display='tab'" *ngIf="display=='grid'" iconBefore="fa-light fa-list-ul"></sg-button>
		<sg-button [reverse]="true" title="Affichage grille" classes="mini" (click)="display='grid'" *ngIf="display=='tab'" iconBefore="fa-light fa-grid-2-plus"></sg-button>
		<sg-button [reverse]="true" title="Afficher moins" classes="mini" (click)="show=false" *ngIf="show" iconBefore="fa-light fa-up-to-line"></sg-button>
		<sg-button [reverse]="true" title="Afficher plus" classes="mini" (click)="show=true" *ngIf="!show" iconBefore="fa-light fa-down-from-line"></sg-button>
	</div>
	<div *ngIf="show">
		<div *ngIf="display=='grid'" class="card-container" [isLoading]="loading">
			<div class="card" *ngFor="let card of cards">		
				<sg-card (addCard)="addCard($event)" [card]="card" [lang]="lang" [set]="getSet(card?.cardSet)"></sg-card>
			</div>
		</div>
		<div *ngIf="cards.length!=0 && display=='tab'" class="card-container-table" [isLoading]='loading'>
			<table>
				<thead>
					<tr>
						<th>Symbole</th>
						<th>Set</th>
						<th>Numero</th>
						<th>Nom</th>
						<th>Date</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let card of cards">
						<td>
							<img class="set-symbol" src="{{getSet(card?.cardSet) | set:'symbol'}}" [sgAltSrc]="['assets/img/icon/logo-unique.png']" alt="{{getSet(card?.cardSet) | set:'name':lang}}"/>
						</td>
						<td>{{getSet(card?.cardSet) | set:'name':lang}}</td>
						<td>{{card?.numero}}</td>
						<td>{{card | card:'name':lang}}</td>
						<td>{{getSet(card?.cardSet).releaseDate | date:'dd/MM/yyyy'}}</td>
						<td>
							<sg-button title="Ajouter la carte" (click)="addCard({card: card, set: getSet(card?.cardSet)})" classes="mini" iconBefore="fa-light fa-plus"></sg-button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div *ngIf="cards.length!=0" class="actions">		
			<a class="link-action" (click)="isCustomCard=true" title="Ajouter une carte">+ Ajouter une carte qui n'est pas dans la liste.</a>
		</div>
		<div *ngIf="cards.length==0 && !pristine" class="empty-cards">
			<i class="fa-light fa-face-beam-hand-over-mouth fa-4x"></i><br/>
			Aucune carte ne correspond à la recherche.<br/>
			<a class="link-action" (click)="isCustomCard=true" title="Ajouter une carte">+ Ajouter une carte qui n'est pas dans la liste.</a>
		</div>
		<div *ngIf="cards.length>0 || page>1" class="pagination">
			<sg-button title="Page précédente" [reverse]="true" [styles]="{margin: '0px 5px'}" classes="mini" iconBefore="fa-light fa-hand-point-left" *ngIf="page>1" (click)="prevPage()"></sg-button>
			{{page}}
			<sg-button title="Page suivante" [reverse]="true" [styles]="{margin: '0px 5px'}" classes="mini" iconBefore="fa-light fa-hand-point-right" (click)="nextPage()"></sg-button>
		</div>
	</div>
</div>
<div *ngIf="isCustomCard">
	<div class="options-cards">
		<sg-button [reverse]="true" classes="mini" (click)="isCustomCard=false" iconBefore="fa-light fa-xmark-large"></sg-button>
	</div>
	<form class="custom-option-container" [formGroup]="formCustom">
		<div class="custom-option-element">
			<div *ngIf="!otherSet">
				<select name="set" class="inputFormat" formControlName="set">
					<option value="">Set de la carte</option>
					<option *ngFor="let set of sets" value="{{set | set:'name':lang}}">{{set | set:'name':lang}}</option>
				</select>
				<a title="Autre" class="link-action" (click)="toggleOtherSet()">+ Autre set</a>
			</div>
			<div *ngIf="otherSet">
				<input type="text" formControlName="set" class="inputFormat" placeholder="Nom du set"/>
				<a title="Autre" class="link-action" (click)="toggleOtherSet()">+ Voir tous les sets</a>
			</div>
		</div>
		<div class="custom-option-element">
			<input type="text" class="inputFormat" formControlName="name" name="name" placeholder="Nom de la carte"/>
		</div>
		<div class="custom-option-element">
			<input type="text" class="inputFormat" formControlName="numero" name="numero" placeholder="Numéro de la carte"/>
		</div>
	</form>
	<div class="custom-option-buttons">
		<sg-button title="Ajouter la carte" iconBefore="fa-light fa-plus" [disabled]="formCustom.invalid" classes="mini" (click)="addCustom()"></sg-button>
	</div>
</div>
