import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button/button.component';
import { IconComponent } from './icon/icon.component';
import { IconReverseComponent } from './icon-reverse/icon-reverse.component';
import { NavigationModule } from './navigation/navigation.module';
import { LoadingDirective } from './loading/loading.directive';
import { AltSrcDirective } from './img/alt-src.directive';
import { RecaptchaComponent } from './recaptcha/recaptcha.component';
import { InitialComponent } from './initial/initial.component';

@NgModule({
  declarations: [
  	ButtonComponent,
  	IconComponent,
  	IconReverseComponent,
  	LoadingDirective,
  	AltSrcDirective,
  	RecaptchaComponent,
  	InitialComponent
  ],
  exports: [
  	ButtonComponent,
  	IconComponent,
  	IconReverseComponent,
  	NavigationModule,
    LoadingDirective,
    AltSrcDirective,
    RecaptchaComponent,
    InitialComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NavigationModule
  ]
})
export class UIModule { }
