import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'sg-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent implements OnInit {
  @Input() classes: string;
  @Input('iconBefore') before: string;
  @Input('iconAfter') after: string;
  @Input() text: string;
  @Input() title: string;
  @Input() link: any;
  @Input() queryParams: any;
  @Input() styles: any;
  @Input() reverse: boolean;
  @Input() disabled: boolean=false;

  constructor() { }

  ngOnInit(): void {
  }

  getClass(){
  	let classes: Array<string>=['button'];
  	if(this.classes!=null)
  		classes.push(this.classes);
  	if(this.reverse)
  		classes.push('reverse');
    if(this.disabled)
      classes.push('disabled');
  	return classes;
  }

  getClassBefore(){
  	return ['before', this.before];
  }

  getClassAfter(){
  	return ['after', this.after];
  }

  isBefore(){
  	return (this.before!=null);
  }

  isAfter(){
  	return (this.after!=null);
  }

}
