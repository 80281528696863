import { Component, OnInit } from '@angular/core';
import { Router, ActivationEnd } from '@angular/router';
import { RouterService } from './../../../services/router.service';

@Component({
  selector: 'sg-button-fixed',
  templateUrl: './button-fixed.component.html',
  styleUrls: ['./button-fixed.component.css']
})
export class ButtonFixedComponent implements OnInit {
  isDisplay: boolean=false;

  constructor(private router: Router, public rs: RouterService){
  }

  ngOnInit(){
  	this.router.events.subscribe(data => {
      if(data instanceof ActivationEnd)
        this.isDisplay=data.snapshot.data.cta;
    });
  }

}
