import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CardService } from './../../../../services/card.service';
import { Card, SetCard } from './../../../../models/card.model';
import { CardPipe } from './../../../../pipes/pipe-card/card.pipe';
import { SetPipe } from './../../../../pipes/pipe-card/set.pipe';

@Component({
  selector: 'sg-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit {
  @Input() card: Card;
  @Input() set: SetCard;
  @Input() lang: string;
  @Output() addCard: EventEmitter<any>;
  selected: boolean=false;

  fliped: boolean= false;

  constructor(public cs: CardService, private cimp: CardPipe, private slogop: SetPipe) {
    this.addCard=new EventEmitter<any>();
  }

  ngOnInit(): void {
  }

  select(){
    this.selected=true;
    this.addCard.emit({
      card: this.card,
      set: this.set
    });
  }

}
