import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Lang, Pays } from './../models/lang.model';

@Injectable({
	providedIn: 'root'
})
export class LangService{
	private langs: Lang[]=[];
	private pays: Pays[]=[];

	constructor(private api: ApiService){}

	getLangs(cache: boolean=true){
		if(!cache || this.langs.length==0){
			return this.api.get<Lang[]>('/langs')
				.pipe(
					tap(langs => this.langs=langs)
				);
		}
		else
			return of(this.langs);
	}

	getPays(){
		if(this.pays.length==0){
			return this.api.get<Pays[]>('/pays')
				.pipe(
					tap(pays => this.pays=pays)
				);
		}
		else
			return of(this.pays);
	}
}