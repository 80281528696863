<div *ngIf="set!=null" [ngClass]="{'card-container': true, fliped: fliped, selected: selected}">
	<div class="back">
		<img src="{{set | set:'logo':lang}}" [sgAltSrc]="set | set:'altLogo':lang" alt="{{set | set:'name':lang}}" class="set-logo"/>
		<div class="card-info box-title">
			{{card | card:'name':lang}}<br/>
			<span class="card-sub-info">
				{{set | set:'name':lang}}<br/>
				N°{{card?.numero}}<br/>
				{{set?.releaseDate | date:'dd/MM/yyyy'}}
			</span>
		</div>
		<div class="buttons-card-back">
			<!-- add button -->
			<a href="#" title="Ajouter cette carte" onclick="return false;" (click)="select()" class="card-button round-icon fa-stack fa-2x">
				<i class="fa-thin fa-circle fa-stack-2x"></i>
				<i class="fa-light fa-plus fa-stack-1x"></i>
			</a>
			<!-- flip button -->
			<span (click)="fliped=false" class="card-button round-icon fa-stack fa-2x">
				<i class="fa-thin fa-circle fa-stack-2x"></i>
				<i class="fa-arrows-repeat fa-light fa-stack-1x"></i>
			</span>
		</div>
	</div>
	<div class="front">
		<img src="{{card | card:'img':lang}}" [sgAltSrc]="card | card:'altImg':lang" alt="{{card.name}}" class="img-card"/>
		<div class="buttons-card">
			<!-- add button -->
			<span class="card-button round-icon fa-stack fa-2x">
				<i class="fa-thin fa-circle fa-stack-2x"></i>
				<span class="card-number">{{card?.numero}}</span>
			</span>
			<!-- flip button -->
			<span (click)="fliped=true" class="card-button round-icon fa-stack fa-2x">
				<i class="fa-thin fa-circle fa-stack-2x"></i>
				<i class="fa-arrows-repeat fa-light fa-stack-1x"></i>
			</span>
			<!-- add button -->
			<a href="#" title="Ajouter cette carte" onclick="return false;" (click)="select()" class="card-button round-icon fa-stack fa-2x">
				<i class="fa-thin fa-circle fa-stack-2x"></i>
				<i class="fa-light fa-plus fa-stack-1x"></i>
			</a>
		</div>
	</div>
</div>
