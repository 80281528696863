<section>
	<sg-header title="Nos boitiers"></sg-header>
	<article class="design">
		<div class="design-img">
			<img src="assets/img/skin/slabs.jpg" alt="Super-Grade Slabs"/>
		</div>
		<div class="design-content">
			<header class="text-content-pad">
				<h2>Nos boitiers : du style et de la qualité</h2>
			</header>
			<div class="text-content-pad text-content">
				Nous savons que pour vous, les collectionneurs, le style est primordiale. C'est pouquoi, nous vous proposons plus de 24 combinaisons pour personnaliser les boitiers de vos cartes.
			</div>
			<div class="design-element-container">
				<div class="design-element">
					<sg-icon-reverse icon="fa-duotone fa-earth-americas"></sg-icon-reverse>
					<span class="box-title">Conçu en France</span>
				</div>
				<div class="design-element">
					<sg-icon-reverse icon="fa-duotone fa-hexagon-image"></sg-icon-reverse>
					<span class="box-title">Design perso</span>
				</div>
				<div class="design-element">
					<sg-icon-reverse icon="fa-duotone fa-explosion"></sg-icon-reverse>
					<span class="box-title">Resiste aux chocs</span>
				</div>
				<div class="design-element">
					<sg-icon-reverse icon="fa-duotone fa-stars"></sg-icon-reverse>
					<span class="box-title">Clear</span>
				</div>
			</div>
			<div class="text-content-pad text-content">
				La qualité est aussi importante que le style, c'est pourquoi tous nos boitiers ont été conçus en France. De plus afin de garantir un qualité inégalée, nous inspéctons tous nos boitiers dans les moindre détails.
			</div>
		</div>
	</article>
	<article class="pres-container custom-container">
		<div class="custom-header">
			<header>
				<h2>Boitiers personnalisables</h2>
			</header>
			Nous mettons à votre dispsition plus de 12 combinaisons de boitiers de protection<br/>
			pour rendre votre collection de cartes encore plus unique.
		</div>
		<sg-customs></sg-customs>
	</article>
	<article class="pres-container">
		<header>
			<h2>Nos étiquettes</h2>
		</header>
		<div class="label-container">
			<div class="label">
				<div class="label-content text-content">
					<h3>Black Label</h3>
					Votre carte est exceptionnelle et le monde doit le savoir ! Ce label est reservée aux cartes qui ont reçu les meilleurs notes dans toutes les composantes de la gradation Super-Grade.
				</div>
				<div class="label-img">
					<img src="assets/img/labels/black-label.jpg" alt="Black Label"/>
				</div>
			</div>
			<div class="label">
				<div class="label-content text-content">
					<h3>Gold Label</h3>
					Votre carte est proche d'un état exceptionnelle et nous savons que c'est très rare ! Ce label est reservée aux cartes qui ont reçu des notes proches du maximum dans toutes les composantes de la gradation Super-Grade.
				</div>
				<div class="label-img">
					<img src="assets/img/labels/gold-label.jpg" alt="Gold Label"/>
				</div>
			</div>
			<div class="label">
				<div class="label-content text-content">
					<h3>Classic Label</h3>
					Votre carte quelques défaut et pourtant nous ne négligerons pas son aspect ! Ce label est reservée aux cartes qui ont reçu des notes commune dans toutes les composantes de la gradation Super-Grade.
				</div>
				<div class="label-img">
					<img src="assets/img/labels/classic-label.jpg" alt="Classic Label"/>
				</div>
			</div>
			<div class="label">
				<div class="label-content text-content">
					<h3>Authentic Label</h3>
					Votre carte est autentique mais vous ne voulais pas la faire évaluer ? Ce label est fait pour vous. Il est reservée aux cartes qui n'ont pas été confrontées à l'echelle de notation Super-Grade mais si qui sont bien authentique.
				</div>
				<div class="label-img">
					<img src="assets/img/labels/authentic-label.jpg" alt="Authentic Label"/>
				</div>
			</div>
		</div>
	</article>
	<sg-start-grading></sg-start-grading>
</section>
