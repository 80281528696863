<sg-header title="Inscription"></sg-header>
<form class="pres-container" [isLoading]="loading" [formGroup]="form">
	<span class="error">{{error}}</span>
	<h2>Vos Informations</h2>
	<div class="elements">
		<input type="text" class="inputFormat" placeholder="Prénom" formControlName="name"/>
		<input type="text" class="inputFormat" placeholder="Nom" formControlName="lastname"/>
		<input type="date" class="inputFormat" placeholder="Date de naissance" formControlName="birth"/>
		<input type="email" class="inputFormat" placeholder="E-mail" formControlName="email"/>
		<input type="password" class="inputFormat" placeholder="Mot de passe" formControlName="pass"/>
	</div>
	<h2>Vos Coordoonées</h2>
	<div class="elements">
		<input type="text" class="inputFormat" placeholder="Tel" formControlName="phone"/>
		<input type="text" class="inputFormat" placeholder="Adresse" formControlName="address"/>
		<input type="text" class="inputFormat" placeholder="Code postal" formControlName="cp"/>
		<input type="text" class="inputFormat" placeholder="Ville" formControlName="city"/>
		<select class="inputFormat" formControlName="pays">
			<option value="">Pays</option>
			<option *ngFor="let p of pays" value="{{p.id}}">{{p.name}}</option>
		</select>
	</div>
	<div class="cgv">
		<input type="checkbox" id="cgv" formControlName="cgv"/>
		<label for="cgv">J'accepte les conditions générales de vente qui sont</label>
		<a href="#" title="Conditions générales de vente" [routerLink]="rs.getRouterLink('cgv')"> ici</a>
	</div>
	<sg-recaptcha (valid)="valid($event)"></sg-recaptcha>
	<div class="buttons">
		<sg-button text="Valider" (click)="register()" [disabled]="form.invalid || !captcha"></sg-button>
	</div>
</form>
