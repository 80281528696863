import { Component, OnInit } from '@angular/core';
import { Card } from './../../../models/card.model';
import { CartService, CardCartOpt } from './../../../services/cart.service';

@Component({
  selector: 'sg-select-cards',
  templateUrl: './select-cards.component.html',
  styleUrls: ['./select-cards.component.css']
})
export class SelectCardsComponent implements OnInit {
  option: CardCartOpt;

  constructor(public cart: CartService) {
  	this.option=new CardCartOpt();
  }

  ngOnInit(): void {
  }

  addCard(card: any){
  	this.cart.addCard(this.option.clone());
  }

}
