import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { SetCard, SetTranslation } from './../../models/card.model';

@Pipe({
  name: 'set'
})
@Injectable({
	providedIn: 'root'
})
export class SetPipe implements PipeTransform {

  transform(set: SetCard, ...args: any[]){
  	let result: any=null;
  	switch(args[0]){
  		case 'name':
  			result=this.getName(set, args.slice(1));
  			break;
  		case 'logo':
  			result=this.getLogo(set, args.slice(1));
  			break;
  		case 'symbol':
  			result=this.getSymbol(set, args.slice(1));
  			break;
  		case 'altLogo':
  			result=this.getAltLogo(set, args.slice(1));
  			break;
  	}
	return result;
  }

  getName(set: SetCard, args: Array<any>){
	let result: string=null;
	let lang: string='en';
	if(args.length>0)
		lang=args[0];
	if(lang)
	  result=set.translations.find(translation => (translation.lang==lang && translation.name==SetTranslation.TS_NAME))?.text;
	if(!result)
		result=set.name;
	return result;
  }

  getLogo(set: SetCard, args: Array<any>){
	let result: string=null;
	let lang: string='en';
	if(args.length>0)
		lang=args[0];
	if(set.logo!=null)
		result=`${SetCard.URL_SET_LOGO}/${lang}/${set.logo}.png`;
	return result;
  }

  getSymbol(set: SetCard, args: Array<any>){
	let result: string=null;
	if(set.logo!=null)
		result=`${SetCard.URL_SET_SYMBOL}/${set.symbol}.png`;
	return result;
  }

  getAltLogo(set: SetCard, args: Array<any>){
  	let result: Array<string>=[];
  	if(args.length>0){
  		if(this.getLogo(set, [args])!=null)
  			result.push(this.getLogo(set, [args]));
  	}
  	if(this.getLogo(set, [])!=null)
  		result.push(this.getLogo(set, []));
  	result.push(SetCard.DEFAULT_LOGO);
  	return result;
  }
}
