import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { Card, Feature } from './../../models/card.model';

@Pipe({
	name: 'card'
})
@Injectable({
	providedIn: 'root'
})
export class CardPipe implements PipeTransform{
	transform(card: Card, ...args: any[]){
		let result: any=null;
		switch(args[0]){
			case 'name':
				result=this.getName(card, args.slice(1));
				break;
			case 'img': 
				result=this.getImg(card, args.slice(1));
				break;
			case 'altImg':
				result=this.getAltImg(card, args.slice(1));
				break;
			default:
				break;
		}
		return result;
	}

	getName(card: Card, args: Array<any>){
		let result: string=null;
		let lang: string='en';
		if(args.length>0)
			lang=args[0];
		if(lang)
			result=card.features.find(ft => (ft.lang==lang && ft.name==Feature.FT_NAME))?.text;
		if(result==null)
			result=card.name;
		return result;
	}

	getImg(card: Card, args: Array<any>){
		let result: string=null;
		let lang: string='en';
		let small: boolean=true;
		if(args.length>0)
			lang=args[0];
		if(args.length>1)
			small=args[1];
		if(card.img!=null){
			let name: string=card.img;
			if(small){
				name=name.replace('.png', '-small.png');
				name=name.replace('.jpg', '-small.jpg');
			}
			result=Card.URL_CARD_IMG+'/'+lang+'/'+name;
		}
		return result;
	}

	getAltImg(card: Card, args: Array<any>){
		let result: Array<string>=[];
		if(args.length>0){
			if(this.getImg(card, args)!=null)
				result.push(this.getImg(card, args));
		}
		if(this.getImg(card, [])!=null)
			result.push(this.getImg(card, []));
		result.push(Card.DEFAULT_IMG);
		return result;
	}
}