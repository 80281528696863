import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Pays } from './../../../models/lang.model';
import { User } from './../../../models/user.model';
import { LangService } from './../../../services/lang.service';
import { UserService } from './../../../services/user.service';
import { RouterService } from './../../../services/router.service';

@Component({
  selector: 'sg-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  pays: Pays[]=[];
  loading: boolean=false;
  form: FormGroup;
  error: string;
  captcha: boolean=false;

  constructor(
    public lang: LangService,
    private fb: FormBuilder,
    private users: UserService,
    private router: Router,
    private activated: ActivatedRoute,
    public rs: RouterService
  ) {
  	loading: true;
  	this.lang.getPays().subscribe(pays => {
  		this.pays=pays;
  		this.loading=false;
  	});
  	this.form=this.fb.group({
  		name: this.fb.control('', [Validators.required]),
  		lastname: this.fb.control('', [Validators.required]),
  		birth: this.fb.control('', [Validators.required]),
  		email: this.fb.control('', [Validators.required, Validators.email]),
  		pass: this.fb.control('', [Validators.required, UserService.isValidPass]),
  		phone: this.fb.control('', [Validators.required]),
  		address: this.fb.control('', [Validators.required]),
  		cp: this.fb.control('', [Validators.required]),
  		city: this.fb.control('', [Validators.required]),
  		pays: this.fb.control('', [Validators.required]),
      cgv: this.fb.control('', [Validators.requiredTrue])
  	});
  }

  ngOnInit(): void {
  }

  register(){
  	this.loading=true;
  	this.users.subscribe(this.form.value)
      .subscribe(
        user => {
          this.registered(user)
        },
        err => {
          this.error=err.error.error
          this.loading=false;
        }
      );
  }

  registered(user: User){
    this.users.register(user)
    this.loading=false;
    this.router.navigate(this.activated.snapshot.queryParams.backurl);
  }

  valid(valid: boolean){
    this.captcha=valid;
  }
}
