<section>
	<sg-header title="Le report"></sg-header>
	<article class="pres-container report-details-container">
		<div>
			<header>
				<h2>Rapport détaillé</h2>
			</header>
			<div class="text-content">
				<b>"Mais... je ne comprends pas ! J'étais certain que cette carte aurait la meilleur note...".</b> Plus de surprise ! Grâce à notre rapport détaillé, vous retrouverez tous les défauts qui caractérisent votre carte et qui affectent sa note.
			</div>

			<div class="criterias-container box-separator">
				<!-- Surface -->
				<div class="criteria">
					<h3>Localisez les défauts</h3>
					<div class="criteria-content">
						<sg-icon icon="fa-light fa-location-dot"></sg-icon>
						Localiser exactement tous les défauts qui affectent la note (micro-rayures, traces d'usure...).
					</div>
				</div>

				<!-- explain -->
				<div class="criteria">
					<h3>Structure de la note</h3>
					<div class="criteria-content">
						<sg-icon icon="fa-light fa-file-certificate"></sg-icon>
						Retrouver tous les détails de la notation de votre carte.
					</div>
				</div>

				<!-- centring -->
				<div class="criteria">
					<h3>Précision</h3>
					<div class="criteria-content">
						<sg-icon icon="fa-light fa-microscope"></sg-icon>
						Détails du calcul de centrage d'impression au 1000ème de miliètre près.
					</div>
				</div>

				<!-- Population -->
				<div class="criteria">
					<h3>Population</h3>
					<div class="criteria-content">
						<sg-icon icon="fa-light fa-chart-mixed"></sg-icon>
						Certifiez la rareté de votre note grâce aux informations de population.
					</div>
				</div>
			</div>
		</div>
		<div class="details-img-container">
			<img src="assets/img/skin/report-3.png" alt="Reporting" class="details-img"/>
		</div>
	</article>
	<article class="pres-container ladder-container">
		<header>
			<h2>Echelle de notation</h2>
			Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br/>
			Donec quis magna ut lacus convallis porta.
		</header>
		<div class="ladder-step-container">
			<div class="ladder-step">
				<div>
					<sg-icon-reverse icon="fa-light fa-arrows-to-circle" [isMini]="false" [x]="4"></sg-icon-reverse>
				</div>
				<div class="ladder-content text-content">
					<h3>Centring</h3>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec quis magna ut lacus convallis porta. 
				</div>
			</div>

			<div class="ladder-step">
				<div>
					<sg-icon-reverse icon="fa-duotone fa-explosion" [isMini]="false" [x]="4"></sg-icon-reverse>	
				</div>
				<div class="ladder-content text-content">
					<h3>Dégâts</h3>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec quis magna ut lacus convallis porta. 
				</div>
			</div>

			<div class="ladder-step">
				<div>
					<sg-icon-reverse icon="fa-duotone fa-paintbrush-fine" [isMini]="false" [x]="4"></sg-icon-reverse>
				</div>
				<div class="ladder-content text-content">
					<h3>Défauts d'impression</h3>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec quis magna ut lacus convallis porta. 
				</div>
			</div>

			<div class="ladder-step">
				<div>
					<sg-icon-reverse icon="fa-duotone fa-flatbread" [isMini]="false" [x]="4"></sg-icon-reverse>
				</div>
				<div class="ladder-content text-content">
					<h3>Défauts de surface</h3>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec quis magna ut lacus convallis porta. 
				</div>
			</div>

			<div class="ladder-step">
				<div>
					<sg-icon-reverse icon="fa-duotone fa-highlighter" [isMini]="false" [x]="4"></sg-icon-reverse>
				</div>
				<div class="ladder-content text-content">
					<h3>Inked</h3>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec quis magna ut lacus convallis porta. 
				</div>
			</div>
		</div>
	</article>
	<sg-start-grading></sg-start-grading>
</section>