import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StartGradingComponent } from './start-grading/start-grading.component';
import { MenuComponent } from './menu/menu.component';
import { HeaderComponent } from './header/header.component';
import { CustomsComponent } from './customs/customs.component';
import { FooterComponent } from './footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UIModule } from '../../core/ui/ui.module';
import { ButtonFixedComponent } from './button-fixed/button-fixed.component';
import { FiveDLayersComponent } from './five-d-layers/five-d-layers.component';
import { SearchCardComponent } from './cards/search-card/search-card.component';
import { CardComponent } from './cards/card/card.component';
import { CardListComponent } from './cards/card-list/card-list.component';
import { PipeCardModule } from './../../pipes/pipe-card/pipe-card.module';

@NgModule({
  declarations: [
    StartGradingComponent,
  	MenuComponent,
  	HeaderComponent,
  	CustomsComponent,
  	FooterComponent,
  	ButtonFixedComponent,
  	FiveDLayersComponent,
  	SearchCardComponent,
  	CardComponent,
  	CardListComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    UIModule,
    FormsModule,
    ReactiveFormsModule,
    PipeCardModule
  ],
  exports: [
  	StartGradingComponent,
  	MenuComponent,
  	HeaderComponent,
  	CustomsComponent,
  	FooterComponent,
    ButtonFixedComponent,
    FiveDLayersComponent,
    SearchCardComponent,
    CardListComponent
  ]
})
export class SectionModule { }
