import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'sg-icon-reverse',
  templateUrl: './icon-reverse.component.html',
  styleUrls: ['./icon-reverse.component.css']
})
export class IconReverseComponent implements OnInit {
  @Input() icon: string;
  @Input() isMini: boolean=true;
  @Input() x: number=2;
  @Input() css: any;

  constructor() { }

  ngOnInit(): void {
  }

  getClass(){
  	return {
  		'round-icon-reverse': true,
  		'mini': this.isMini
  	};
  }

  getIcon(){
  	return [
  		this.icon,
  		`fa-${this.x}x`
  	];
  }
}
