import { Component, OnInit, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { ScriptLoaderService, ScriptModel } from './../../../services/script-loader.service';
declare var grecaptcha: any;

@Component({
  selector: 'sg-recaptcha',
  templateUrl: './recaptcha.component.html',
  styleUrls: ['./recaptcha.component.css']
})
export class RecaptchaComponent implements OnInit {
  public static readonly key: string='6Ldb_ckhAAAAAHLVy-eYhC7G4iuPL4m_JWcKw_ed';
  private static id: number=0;
  callback: string;
  @Output() valid: EventEmitter<boolean>;
  
  constructor(private loader: ScriptLoaderService) {
  	this.valid=new EventEmitter<boolean>();
  	this.setCallback();
  	RecaptchaComponent.id++;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(){
  	this.loader.load({
  		name: 'grecaptcha',
  		src: 'https://www.google.com/recaptcha/api.js?render=explicit',
  		loaded: false
  	}).subscribe(script => {
  		if(script.loaded){
  			grecaptcha.ready(() => {
				grecaptcha.render(this.getId(), {
	  				'sitekey': RecaptchaComponent.key
	  			});
  			});
  		}
  	});
  }

  getId(){
  	return 'captcha-'+RecaptchaComponent.id;
  }

  setCallback(){
  	this.callback='captchaCallback'+RecaptchaComponent.id;
  	window[''+this.callback]=(response: any) => {
  		this.valid.emit(true);
  	};
  }
}
