import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Price } from './../models/price.model';
import { tap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class PriceService{
	private prices: Price[];

	constructor(private api: ApiService){}

	getPrices(){
		if(this.prices==null){
			return this.api.get<Price[]>('/prices')
				.pipe(
					tap(prices => this.prices=prices)
				);
		}
		else
			return of(this.prices);
	}
}