import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { RouterService } from './../../../services/router.service';
import { UserService } from './../../../services/user.service';

@Component({
  selector: 'sg-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  connect: boolean=true;
  loading: boolean=false;
  msg: string;

  constructor(public rs: RouterService, private user: UserService) { }

  ngOnInit(): void {
  }

  forgot(email: string){
  	this.loading=true;
  	this.user.forgot(email).subscribe(() => {
  		this.msg='Un e-mail a été envoyé ! Il contient les informations pour réinitialiser votre mot de passe.';
  		this.loading=false;
  	});
  }
}
