<sg-header title="Gradez !"></sg-header>
<div class="pres-container">
	<sg-thread>
		<sg-step name="Cartes" icon="fa-duotone fa-cards-blank" [link]="rs.getRouterLink('grading.select-cards')"></sg-step>
		<sg-step name="Validation" icon="fa-duotone fa-check" [link]="rs.getRouterLink('grading.check-cart')"></sg-step>
		<sg-step name="Paiement" icon="fa-duotone fa-wallet" [link]="rs.getRouterLink('grading.paiement')"></sg-step>
		<sg-step name="Envoie de la commande" icon="fa-duotone fa-truck-fast" [link]="['a']"></sg-step>
	</sg-thread>
</div>
<router-outlet></router-outlet>
