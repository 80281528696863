import { Injectable } from '@angular/core';
import { tap, map, filter } from 'rxjs/operators';
import { pipe, of } from 'rxjs';
import { TypeCard, SetCard, Card, SetTranslation, Feature } from './../models/card.model';
import { ApiService } from './../services/api.service';


@Injectable({
	providedIn: 'root'
})
export class CardService{
	private types: TypeCard[]=[];
	private sets: Map<string, SetCard[]>=new Map<string, SetCard[]>();

	constructor(private api: ApiService){ }

	getTypes(){
		if(this.types.length==0){
			return this.api.get<TypeCard[]>('/cards/types')
				.pipe(
					tap(types => this.types=types)
				);
		}
		else
			return of(this.types);
	}

	getSets(type: string){
		if(!this.sets.get(type)){
			return this.api.get<SetCard[]>(`/cards/${type}/sets`)
				.pipe(
					tap(sets => this.sets.set(type, sets))
				);
		}
		else
			return of(this.sets.get(type));
	}

	getSet(type: string, ident: string){
		return this.getSets(type)
			.pipe(
				filter(set => set.ident==ident),
				map(sets => ((sets.length>0)? sets[0] : null))
			)
	}

	getSetTranslation(set: SetCard, lang: string="en"){
		return set.translations.filter(ts => ts.lang==lang);
	}

	search(search: string, type: string, lang: string, start: number=0, length: number=50){
		let params={
			search: search,
			lang: lang,
			start: start,
			length: length
		};
		return this.api.get<Card[]>(`/cards/${type}/search`, {params});
	}
}