import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { tap } from 'rxjs/operators';
import { Card, SetCard } from './../../../../models/card.model';
import { CardService } from './../../../../services/card.service';

@Component({
  selector: 'sg-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.css']
})
export class CardListComponent implements OnInit {
  @Input() cards: Array<Card>=[];
  @Input() lang: string;
  @Input() page: number=1;
  @Input() pristine: boolean=true;
  @Output() changePage: EventEmitter<number>;
  @Output('addCard') select: EventEmitter<Card>;
  @Output('addCutomCard') selectCustom: EventEmitter<any>;
  loading: boolean=false;
  sets: SetCard[]=[];
  show: boolean=true;
  display: 'grid' | 'tab'='grid'; 
  isCustomCard: boolean=false;
  formCustom: FormGroup;
  otherSet: boolean=false;

  @Input() 
  set type(type: string){
  	if(type){
	  	this.loading=true;
	  	this.cs.getSets(type)
	  		.pipe(
	  			tap(() => this.loading=false)
	  		)
	  		.subscribe(sets =>  this.sets=sets);
  	}
  }

  constructor(public cs: CardService, private fb: FormBuilder) {
    this.changePage=new EventEmitter();
    this.select=new EventEmitter<Card>();
    this.selectCustom=new EventEmitter<any>();
    this.formCustom=this.fb.group({
      set: this.fb.control('', Validators.required),
      name: this.fb.control('', Validators.required),
      numero: this.fb.control('', Validators.required)
    });
  }

  ngOnInit(): void { }

  getSet(ident: string){
  	return this.sets.find(set => set.ident==ident);
  }

  nextPage(){
  	this.changePage.emit(this.page+1);
  }

  prevPage(){
  	this.changePage.emit(this.page-1);
  }

  addCard(card: any){
    this.select.emit(card);
  }

  toggleOtherSet(){
    this.otherSet=!this.otherSet;
    this.formCustom.controls['set'].setValue('');
  }

  addCustom(){
    console.log(this.formCustom.valid);
    if(this.formCustom.valid){
      this.selectCustom.emit({
        name: this.formCustom.controls['name'].value,
        set: this.formCustom.controls['set'].value,
        numero: this.formCustom.controls['numero'].value,
      }); 
    }
  }
}
