<div class="view">
	<div class="bloc-container">
		<form *ngIf="connect">
			<h1>Connectez-vous</h1>
			<input type="email" class="inputFormat" placeholder="E-mail" />
			<input type="password" class="inputFormat" placeholder="Mot de passe" />
			<div class="forgot">
				<a (click)="connect=false" title="Mot de passe oublié" class="link-action">+ Mot de passe oublié</a>
			</div>
			<div class="buttons">		
				<sg-button text="Valider"></sg-button><br/>
			</div>
			<div class="register">
				<a title="Inscription" [routerLink]="rs.getRouterLink('register')" queryParamsHandling="merge" class="link-action">Ou s'inscrire</a>
			</div>
		</form>
		<form *ngIf="!connect" [isLoading]="loading">
			<h1>Mot de passe oublié</h1>
			{{msg}}<br/>
			<input type="email" class="inputFormat" placeholder="Votre e-mail" #mailForgot/>
			<div class="buttons">		
				<sg-button (click)="connect=true" classes="white no-shadow" text="Retour"></sg-button>
				<sg-button (click)="forgot(mailForgot.value)" text="Valider"></sg-button>
			</div>
		</form>
	</div>
	<div class="right-connect">
		<img alt="Slab Super-Grade" src="assets/img/skin/hero-1.png"/>
	</div>
</div>