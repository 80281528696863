<section>
	<sg-header title="Grading"></sg-header>
	<article class="pres-container">
		<header class="sg-title">
			<h2>Pourquoi Super-Grade ?</h2>
			<div>
				Avec nos services nos grading, Super-Grade vous offre une expérience hors du commun.<br/>
				Personnalisez, découvrez et vivez votre collection comme vous ne l'avez jamasi fait auparavant.
			</div>
		</header>
		<div class="sg-container">
			<div class="sg-box">
				<div class="sg-content">
					<div class="box-title">
						Salle blanche
					</div>
					Tout le process se déroule en salle blanche.
				</div>
				<div>
					<div class="box-icon">
						<i class="fa-duotone fa-radiation fa-3x"></i>
					</div>
				</div>
			</div>

			<div class="sg-box">
				<div class="sg-content">
					<div class="box-title">
						Reporting
					</div>
					Un reporting détaillé est à votre disposition.
				</div>
				<div>
					<div class="box-icon">
						<i class="fa-regular fa-chart-mixed fa-3x"></i>
					</div>
				</div>
			</div>

			<div class="sg-box">
				<div class="sg-content">
					<div class="box-title">
						Assistance IA
					</div>
					Plus de place à la subjectivité.
				</div>
				<div>
					<div class="box-icon">
						<i class="fa-regular fa-3x fa-square-root-variable"></i>
					</div>
				</div>
			</div>

			<div class="sg-box">
				<div class="sg-content">
					<div class="box-title">
						Vue 5D
					</div>
					Visualisez votre collection sous tous les angles.
				</div>
				<div>
					<div class="box-icon">
						<i class="fa-duotone fa-3x fa-layer-group"></i>
					</div>
				</div>
			</div>

			<div class="sg-box">
				<div class="sg-content">
					<div class="box-title">
						Design
					</div>
					Personnlisez vos boitiers
				</div>
				<div>
					<div class="box-icon">
						<i class="fa-duotone fa-hexagon-image fa-3x fa-fw"></i>
					</div>
				</div>
			</div>

			<div class="sg-box">
				<div class="sg-content">
					<div class="box-title">
						Transparence
					</div>
					Retrouvez toutes les détails de la notation
				</div>
				<div>
					<div class="box-icon">
						<i class="fa-duotone fa-tags fa-3x fa-fw"></i>
					</div>
				</div>
			</div>
		</div>
	</article>
	<article class="grading-middle-container">
		<div class="grading-midle-img">
			<img src="assets/img/skin/hero-1.png" alt="Grading slab"/>
		</div>
		<div class="grading-middle-content">
			<header>
				<h2>Qu'est-ce que le grading ?</h2>
			</header>
			<div>
				Comme tout objet de collection, la valeur d'une carte se définie par sa rareté et son état. Les collectionneurs cherchent à valoriser au mieux leur collection et la protéger. Pour cela, ils font appel à une tiers de confience comme Super-Grade au travers d'un process nommé le grading. Ce processe consiste à :
			</div>
			<div class="grading-criterias">
				<div class="grading-criteria">
					<sg-icon-reverse icon="fa-duotone fa-file-certificate"></sg-icon-reverse>
					<span class="grading-title">Vérifier l'authenticité</span>
				</div>

				<!-- Vue IR -->
				<div class="grading-criteria">
					<sg-icon-reverse icon="fa-duotone fa-satellite-dish fa-rotate-90"></sg-icon-reverse>
					<span class="grading-title">Rechercher les défauts</span>
				</div>

				<!-- Vue UV -->
				<div class="grading-criteria">
					<sg-icon-reverse icon="fa-duotone fa-magnifying-glass"></sg-icon-reverse>
					<span class="grading-title">Noter la carte</span>
				</div>

				<!-- Vue topographique -->
				<div class="grading-criteria">
					<sg-icon-reverse icon="fa-solid fa-shield"></sg-icon-reverse>
					<span class="grading-title">Protéger la carte</span>
				</div>
			</div>
			<div>
				Ainsi, le tiers de confience atteste de l'authenticité de votre carte, recherche les défauts (micro rayure, point blanc, trace d'usure...), évalue l'état de la carte et protège votre carte dans un boitier.
			</div>
		</div>
	</article>
	<article class="pres-container">
		<div class="header-container">
			<header>
				<h2 class="step-title">Les étapes du grading</h2>
			</header>
			<div>
				Entre le moment de la commande et la récéption des cartes gradées, il y a plusieurs étapes. Lors de toutes ces étapes, vos cartes sont chouchouttées et soigneusement manipulées.
			</div>
		</div>
		<div class="step-container">
			<div class="step-line">
				<!-- step 1 -->
				<div class="step">
					<div class="step-icon">
						<i class="fa-duotone fa-cart-shopping-fast fa-4x"></i>
						<i class="exp fa-duotone fa-circle-1 fa-2x"></i>
					</div>
					<div class="step-text">
						<div class="step-title">
							Commandez
						</div>
						<div>
							Commander vos presation sur notre site internet.
						</div>
					</div>
				</div>
				
				<!-- step 2 -->
				<div class="step">
					<div class="step-icon">
						<i class="fa-duotone fa-starfighter-twin-ion-engine fa-4x"></i>
						<i class="exp fa-duotone fa-circle-2 fa-2x"></i>
					</div>
					<div class="step-text">
						<div class="step-title">
							Expédiez vos cartes
						</div>
						<div>
							Envoyez-nous vos cartes ou déposez les en boutique.
						</div>
					</div>
				</div>
				
				<!-- step 3 -->
				<div class="step">
					<div class="step-icon">
						<i class="fa-duotone fa-washing-machine fa-4x"></i>
						<!-- <i class="fa-duotone fa-hand-sparkles fa-4x"></i> -->
						<i class="exp fa-duotone fa-circle-3 fa-2x"></i>
					</div>
					<div class="step-text">
						<div class="step-title">
							Récéption et nettoyage
						</div>
						<div>
							Nous receptionnons et nettoyons vos cartes.
						</div>
					</div>
				</div>
			</div>
			
			<div class="step-line">
				<!-- step 4 -->
				<div class="step">
					<div class="step-icon">
						<i class="fa-duotone fa-file-certificate fa-4x"></i>
						<i class="exp fa-duotone fa-circle-4 fa-2x"></i>
					</div>
					<div class="step-text">
						<div class="step-title">
							Grading
						</div>
						<div>
							Nous vérifions l'authenticité et évaluons l'état de votre carte.
						</div>
					</div>
				</div>

				<!-- step 5 -->
				<div class="step">
					<div class="step-icon">
						<i class="fa-duotone fa-hexagon-image fa-4x"></i>
						<i class="exp fa-duotone fa-circle-5 fa-2x"></i>
					</div>
					<div class="step-text">
						<div class="step-title">
							Protection
						</div>
						<div>
							Nous empaquetons votre carte dans un boitier hermétique.
						</div>
					</div>
				</div>
				
				<!-- step 6 -->
				<div class="step">
					<div class="step-icon">
						<i class="fa-duotone fa-truck-fast fa-4x"></i>
						<i class="exp fa-duotone fa-circle-6 fa-2x"></i>
					</div>
					<div class="step-text">
						<div class="step-title">
							Retour de la commande
						</div>
						<div>
							Nous vous renvoyons vos cartes.
						</div>
					</div>
				</div>
			</div>
		</div>
	</article>
	<sg-start-grading></sg-start-grading>
</section>