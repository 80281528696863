import { Component, OnInit } from '@angular/core';
import { RouterService } from './../../../services/router.service';

@Component({
  selector: 'sg-start-grading',
  templateUrl: './start-grading.component.html',
  styleUrls: ['./start-grading.component.css']
})
export class StartGradingComponent implements OnInit {

  constructor(public rs: RouterService) { }

  ngOnInit(): void {
  }

}
