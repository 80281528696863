import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UIModule } from './../../core/ui/ui.module';
import { PurchaseGradingComponent } from './purchase-grading/purchase-grading.component';
import { SelectCardsComponent } from './select-cards/select-cards.component';
import { SectionModule } from './../section/section.module';
import { CardTabComponent } from './card-tab/card-tab.component';
import { OptionGradingComponent } from './option-grading/option-grading.component';
import { FormsModule } from '@angular/forms';
import { PipeCardModule } from './../../pipes/pipe-card/pipe-card.module';
import { CheckCartComponent } from './check-cart/check-cart.component';
import { PaiementComponent } from './paiement/paiement.component';

@NgModule({
  declarations: [
  	PurchaseGradingComponent,
  	SelectCardsComponent,
  	CardTabComponent,
  	OptionGradingComponent,
  	CheckCartComponent,
  	PaiementComponent
  ],
  exports: [
  	PurchaseGradingComponent,
  	SelectCardsComponent
  ],
  imports: [
    CommonModule,
    UIModule,
    RouterModule,
    SectionModule,
    FormsModule, 
    PipeCardModule
  ]
})
export class OrderGradingModule { }
