import { Component, OnInit } from '@angular/core';
import { RouterService } from './../../../services/router.service';

@Component({
  selector: 'sg-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(public rs: RouterService) { }

  ngOnInit(): void {
  }

}
