import { Injectable } from '@angular/core';
import { ROUTES } from '../app.routes';

@Injectable({
	providedIn: 'root'
})
export class RouterService{

	//retourne tous les objet routes
	getRoutes(){
		return ROUTES;
	}

	//retourne la route correspondant à name
	getRoute(name: string){
		let result: any=null;
		this.getRoutes().forEach((route: any) => {
			if(!route.children && route.data.name==name)
				result=route;
			else if(route.children){
				route.children.forEach((children: any) => {
					if(children.data.name==name)
						result=children;
				});
			}
		});

		return result;
	}

	//retourne le path d'une route qui correspond à name
	getRoutePath(name: String){
		let result: string=null;
		this.getRoutes().forEach((route: any) => {
			if(!route.children && route.data.name==name)
				result=route.path;
			else if(route.children){
				route.children.forEach((children: any) => {
					if(children.data.name==name)
						result=route.path+'/'+children.path;
				});
			}
		});

		return result;
	}

	//retourne le tableau pour contrsuire la route à mettre dans routerlink d'une balise <a>
	getRouterLink(name: string, params: any=[]){
		let path: string=null;
		let route: any=this.getRoutePath(name);
		if(route)
			path='/'+route;
		let result: any[]=[path];
		result.concat(params);
		return result;
	}
}