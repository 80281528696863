import { Directive, Input,  ElementRef, Renderer2} from '@angular/core';

@Directive({
  selector: '[isLoading]'
})
export class LoadingDirective {

  constructor(private element: ElementRef<HTMLElement>, private render: Renderer2) { }

  @Input()
  set isLoading(loading: boolean){
  	if(loading)
	  	this.render.addClass(this.element.nativeElement, "loader");
	else
	  	this.render.removeClass(this.element.nativeElement, "loader");
  }
}
