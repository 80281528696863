import { Directive, Input, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: 'img[sgAltSrc]'
})
export class AltSrcDirective {
  @Input() sgAltSrc: Array<string>=[];
  index: number=0;

  constructor(private element: ElementRef<HTMLImageElement>) {
      if(!this.element.nativeElement.hasAttribute("src")){
        this.element.nativeElement.setAttribute("src", this.sgAltSrc[0]);
        this.nextAlt();
      }
  }

  @HostListener('error')
  setSrc(){
    this.nextAlt();
  }

  nextAlt(){
    if(this.index<this.sgAltSrc.length){
      this.element.nativeElement.src=this.sgAltSrc[this.index];
      this.index++; 
    }
  }
}
