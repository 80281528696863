import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardPipe } from './card.pipe';
import { SetPipe } from './set.pipe';


@NgModule({
  declarations: [
  	CardPipe,
  	SetPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
  	CardPipe,
  	SetPipe
  ]
})
export class PipeCardModule { }
