import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sg-five-d',
  templateUrl: './five-d.component.html',
  styleUrls: ['./five-d.component.css']
})
export class FiveDComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
