import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivationEnd } from '@angular/router';
import { RouterService } from '../../../services/router.service';

@Component({
  selector: 'sg-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  transiant: boolean=true;
  overrideTransiant: boolean=false;
  isOpen: boolean=false;

  constructor(private router: Router, public rs: RouterService) { }

  ngOnInit(){
    this.router.events.subscribe(data => {
      if(data instanceof ActivationEnd && data.snapshot.data.overrideTransiant){
        this.overrideTransiant=true;
        this.transiant=false;
      }
      else if(data instanceof ActivationEnd){
        this.overrideTransiant=false;
        this.transiant=true; 
      }
    });
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event: any) {
  	this.transiant=(window.scrollY<2 && !this.overrideTransiant);
  }

  toggleMenu(){
    this.isOpen=!this.isOpen;
  }
}
