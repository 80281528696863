import { Component,
  OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RouterService } from './../../../services/router.service';
import { CartService } from './../../../services/cart.service';
import { PriceService } from './../../../services/price.service';
import { UserService } from './../../../services/user.service';
import { Price } from './../../../models/price.model';

@Component({
  selector: 'sg-check-cart',
  templateUrl: './check-cart.component.html',
  styleUrls: ['./check-cart.component.css']
})
export class CheckCartComponent implements OnInit {
  loading: boolean=false;
  prices: Price[]=[];

  constructor(
      public rs: RouterService,
      public cart: CartService,
      private price: PriceService,
      private users: UserService,
      private router: Router
    ) {
  	this.loading=true;
  	this.price.getPrices()
  		.subscribe(prices => {
			this.prices=prices;
			this.loading=false;
  		});
  }

  ngOnInit(): void {
  }

  //méthode qui retourne le listing des prices 
  getPrices(){
  	let result: any[]=[];
  	let grading: any=this.getGradingPrices();
  	if(grading.qte>0)
  		result.push(grading);
  	let magnet:any=this.getMagnetPrices();
  	if(magnet.qte>0)
  		result.push(magnet);
  	let fiveD:any=this.get5DPrices();
  	if(fiveD.qte>0)
  		result.push(fiveD);
  	let nft:any=this.getNftPrices();
  	if(nft.qte>0)
  		result.push(nft);
  	let guarantee: any=this.getGuaranteePrices();
  	if(guarantee.qte>0)
  		result.push(guarantee);
  	let shiping:any=this.getShipingPrices();
  	if(shiping.qte>0)
  		result.push(shiping);
  	return result;
  }

  //méthode qui retourne les prices de grading
  getGradingPrices(){
  	let price: Price=this.prices.find(p => p.item==Price.GRADING_ITEM);
  	let line: any={
  		qte: 0,
  		name: 'Grading',
  		pu: 0,
  		total: 0
  	};

  	if(price){
  		line.name=price.name;
  		line.pu=price.value;
  		this.cart.getCards().forEach(option => line.qte+=option.qte);
  		line.total=line.qte*line.pu;
  	}

  	return line;
  }

  //méthode qui retourne les prices de grading
  getGuaranteePrices(){
  	let line: any={
  		qte: 0,
  		name: 'Assurance',
  		pu: null,
  		total: 0
  	};

  	this.cart.getCards().forEach(option => {
  		let price=this.prices.find(price => (price.item==Price.GUARANTEE_ITEM && price.min<=option.value && (price.max==null || price.max>=option.value)));
  		if(price){
	  		line.qte+=option.qte;
	  		line.total+=option.qte*price.value;	
  		}
  	});

  	return line;
  }

  //méthode qui retourne les prices de grading
  getShipingPrices(){
  	let line: any={
  		qte: 0,
  		name: 'Frais d\'envoi',
  		pu: null,
  		total: 0
  	};

  	this.cart.getCards().forEach(option => line.qte+=option.qte);
  	let price: Price=this.prices.find(price => (price.item==Price.SHIP_ITEM && price.min<=line.qte && (price.max==null || price.max>=line.qte)));
  	if(price){
  		line.total=price.value;
  		line.name=price.name;
  	}

  	return line;
  }

  //méthode qui retourne les prices de grading
  getNftPrices(){
  	let line: any={
  		qte: 0,
  		name: 'NFT',
  		pu: 0,
  		total: 0
  	};

   	let price: Price=this.prices.find(p => p.item==Price.NFT_ITEM);
   	if(price){
   		line.name=price.name;
   		line.pu=price.value;
   		this.cart.getCards()
   			.filter(option => option.nft)
   			.forEach(option => line.qte+=option.qte);
   		line.total=line.pu*line.qte;
   	}

  	return line;
  }

  //méthode qui retourne les prices de grading
  get5DPrices(){
  	let line: any={
  		qte: 0,
  		name: '5D',
  		pu: 0,
  		total: 0
  	};

   	let price: Price=this.prices.find(p => p.item==Price.FIVED_ITEM);
   	if(price){
   		line.name=price.name;
   		line.pu=price.value;
   		this.cart.getCards()
   			.filter(option => option.fiveD)
   			.forEach(option => line.qte+=option.qte);
   		line.total=line.pu*line.qte;
   	}

  	return line;
  }

  //méthode qui retourne les prices de grading
  getMagnetPrices(){
  	let line: any={
  		qte: 0,
  		name: 'Aimant',
  		pu: 0,
  		total: 0
  	};

   	let price: Price=this.prices.find(p => p.item==Price.MAGNET_ITEM);
   	if(price){
   		line.name=price.name;
   		line.pu=price.value;
   		this.cart.getCards()
   			.filter(option => option.slab.magnet)
   			.forEach(option => line.qte+=option.qte);
   		line.total=line.pu*line.qte;
   	}

  	return line;
  }

  getTotal(){
  	let result: number=0;
  	this.getPrices().forEach(line => result+=line.total);
  	return result;
  }

  getNextStep(){
     if(this.users.isConnected())
       return this.rs.getRouterLink('grading.paiement');
     else
       return this.rs.getRouterLink('login');
  }

  getQueryParam(){
     if(!this.users.isConnected())
       return {backurl: this.rs.getRouterLink('grading.paiement')};
     return {};
  }
}
