import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'sg-initial',
  templateUrl: './initial.component.html',
  styleUrls: ['./initial.component.css']
})
export class InitialComponent implements OnInit {
  @Input() text: string;
  @Input() width: number=40;
  @Input() size: number=1;

  constructor() { }

  ngOnInit(): void {
  }

  getInitial(){
  	let result: string='';
  	this.text.split(' ').forEach(word => result+=word.substring(0, 1));
  	return result;
  }

  getStyle(){
  	return {
  		width: `${this.width}px`,
  		height: `${this.width}px`,
  		'font-size': `${this.size}rem` 
  	};
  }

}
