import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sg-grading',
  templateUrl: './grading.component.html',
  styleUrls: ['./grading.component.css']
})
export class GradingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
