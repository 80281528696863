import { Component, OnInit } from '@angular/core';

/**
* utilisation : 
* <sg-thread>
* 	<step name="" link="" icon=""></step>
* </sg-thread>
*/

@Component({
  selector: 'sg-thread',
  templateUrl: './thread.component.html',
  styleUrls: ['./thread.component.css']
})
export class ThreadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
