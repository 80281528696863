<div class="cart-empty" *ngIf="cart.isEmpty()">
	<i class="fa-light fa-face-beam-hand-over-mouth fa-4x"></i><br/>
	Votre panier ne contient aucune carte.
</div>
<div class="table-container" *ngIf="!cart.isEmpty()">
	<table >
		<thead>
			<tr>
				<th class="col-1">Qte</th>
				<th class="col-2">Nom</th>
				<th class="col-3">Set</th>
				<th class="col-4">Langue</th>
				<th class="col-5">N°</th>
				<th class="col-6">Valeur</th>
				<th class="col-7">Options</th>
				<th class="col-10">5D</th>
				<th class="col-11">NFT</th>
				<th class="col-12"></th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let option of cart.getCards(); index as i">
				<td class="col-1">
					<input type="number" class="inputFormat" [(ngModel)]="option.qte"/>
				</td>
				<td class="col-2">
					{{option.card}}
				</td>
				<td class="col-3">
					{{option.set}}
				</td>
				<td class="col-4">
					{{option.lang | uppercase}}
				</td>
				<td class="col-5">
					{{option.numero}}
				</td>
				<td class="col-6">
					<input type="number" class="inputFormat" [(ngModel)]="option.value"/>
				</td>
				<td class="col-7">
					<span class="option-icon">
						<i *ngIf="option.slab.form=='R'" class="fa-light fa-sidebar fa-rotate-90"></i>
						<i *ngIf="option.slab.form=='C'" class="fa-light fa-sidebar"></i>
						<i *ngIf="option.slab.form=='H'" class="fa-regular fa-hexagon-image"></i>
					</span>
					<span class="option-icon">
						<i *ngIf="option.slab.position=='H'" class="fa-light fa-arrow-down-to-bracket fa-rotate-180"></i>
						<i *ngIf="option.slab.position=='D'" class="fa-light fa-arrow-down-to-bracket fa-rotate-270"></i>
						<i *ngIf="option.slab.position=='B'" class="fa-light fa-arrow-down-to-bracket"></i>
						<i *ngIf="option.slab.position=='G'" class="fa-light fa-arrow-down-to-bracket fa-rotate-90"></i>
					</span>
					<span class="option-icon">
						<i *ngIf="option.slab.magnet" class="fa-duotone fa-bolt-lightning"></i>
					</span>
				</td>
				<td class="col-10">
					<i *ngIf="option.fiveD" class="fa-light fa-cube"></i>
					<i *ngIf="!option.fiveD" class="fa-light fa-empty-set"></i>
				</td>
				<td class="col-11">
					<i *ngIf="option.nft" class="fa-light fa-file-certificate"></i>
					<i *ngIf="!option.nft" class="fa-light fa-empty-set"></i>
				</td>
				<td class="col-12">
					<sg-button title="Supprimer la carte" iconBefore="fa-light fa-trash-can" classes="mini" (click)="rmOption(i)"></sg-button>
				</td>
			</tr>
		</tbody>
	</table>
</div>
<div *ngIf="!cart.isEmpty()" class="buttons-container">
	<sg-button (click)="nextStep()" title="Vérifier le panier" iconBefore="fa-solid fa-arrow-right"></sg-button>
</div>