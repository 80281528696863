import { Routes } from '@angular/router';
import { HomeComponent} from './components/page/home/home.component';
import { GradingComponent } from './components/page/grading/grading.component';
import { SlabComponent } from './components/page/slab/slab.component';
import { ReportComponent } from './components/page/report/report.component';
import { FiveDComponent } from './components/page/five-d/five-d.component';
import { NftComponent } from './components/page/nft/nft.component';
import { PurchaseGradingComponent } from './components/order-grading/purchase-grading/purchase-grading.component';
import { SelectCardsComponent } from './components/order-grading/select-cards/select-cards.component';
import { CheckCartComponent } from './components/order-grading/check-cart/check-cart.component';
import { LoginComponent } from './components/users/login/login.component';
import { RegisterComponent } from './components/users/register/register.component';
import { CgvComponent } from './components/page/cgv/cgv.component';
import { PaiementComponent } from './components/order-grading/paiement/paiement.component';

export const ROUTES: any=[
	{path: '', component: HomeComponent, data: {name: 'root', cta: true}},
	{path: 'home', component: HomeComponent, data: {name: 'home', cta: true}},
	{path: 'grading', component: GradingComponent, data: {name: 'grading', cta: true}},
	{path: 'slab', component: SlabComponent, data: {name: 'slab', cta: true}},
	{path: 'report', component: ReportComponent, data: {name: 'report', cta: true}},
	{path: 'view-5-D', component: FiveDComponent, data: {name: 'five-d', cta: true}},
	{path: 'nft', component: NftComponent, data: {name: 'nft', cta: true, overrideTransiant: true}},
	{path: 'cgv', component: CgvComponent, data: {name: 'cgv'}},
	{path: 'default', component: HomeComponent, data: {name: 'default'}},
	{
		path: 'order-grading',
		component: PurchaseGradingComponent,
		children: [
			{path: 'select-cards', component: SelectCardsComponent, data: {name: 'grading.select-cards'}},
			{path: 'check', component: CheckCartComponent, data: {name: 'grading.check-cart'}},
			{path: 'paiement', component: PaiementComponent, data: {name: 'grading.paiement'}},
		]
	},
	{path: 'login', component: LoginComponent, data: {name: 'login', overrideTransiant: true}},
	{path: 'register', component: RegisterComponent, data: {name: 'register'}}
];