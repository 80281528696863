<div class="bloc-container cart-empty" *ngIf="cart.isEmpty()">
	<i class="fa-light fa-face-beam-hand-over-mouth fa-4x"></i><br/>
	Votre panier ne contient aucune carte.<br/>
	<a [routerLink]="rs.getRouterLink('grading.select-cards')" class="link-action" title="Ajouter des cartes">+ Ajouter des cartes</a>
</div>

<div *ngIf="!cart.isEmpty()" class="bloc-container">
	<table>
		<thead>
			<tr>
				<th>Qte</th>
				<th>Nom</th>
				<th>Set</th>
				<th>Langue</th>
				<th>N°</th>
				<th>Valeur</th>
				<th>Options</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let option of cart.getCards()">
				<td>{{option.qte}}</td>
				<td>{{option.card}}</td>
				<td>{{option.set}}</td>
				<td>{{option.lang | uppercase}}</td>
				<td>{{option.numero}}</td>
				<td>{{option.value}}</td>
				<td class="col-icon">
					<span class="option-icon">
						<i *ngIf="option.slab.form=='R'" class="fa-light fa-sidebar fa-rotate-90"></i>
						<i *ngIf="option.slab.form=='C'" class="fa-light fa-sidebar"></i>
						<i *ngIf="option.slab.form=='H'" class="fa-regular fa-hexagon-image"></i>
					</span>
					<span class="option-icon">
						<i *ngIf="option.slab.position=='H'" class="fa-light fa-arrow-down-to-bracket fa-rotate-180"></i>
						<i *ngIf="option.slab.position=='D'" class="fa-light fa-arrow-down-to-bracket fa-rotate-270"></i>
						<i *ngIf="option.slab.position=='B'" class="fa-light fa-arrow-down-to-bracket"></i>
						<i *ngIf="option.slab.position=='G'" class="fa-light fa-arrow-down-to-bracket fa-rotate-90"></i>
					</span>
					<span class="option-icon">
						<i *ngIf="option.slab.magnet" class="fa-duotone fa-bolt-lightning"></i>
						<i *ngIf="!option.slab.magnet" class="fa-duotone fa-bolt-slash"></i>
					</span>
					<span class="option-icon">
						<i *ngIf="option.fiveD" class="fa-light fa-cube"></i>
						<i *ngIf="!option.fiveD" class="fa-light fa-empty-set"></i>
					</span>
					<span class="option-icon">
						<i *ngIf="option.nft" class="fa-light fa-file-certificate"></i>
						<i *ngIf="!option.nft" class="fa-light fa-empty-set"></i>
					</span>
				</td>
			</tr>
		</tbody>
	</table>
</div>

<div *ngIf="!cart.isEmpty()" class="bloc-container" [isLoading]="loading">
	<table>
		<thead>
			<tr>
				<th>Qte</th>
				<th>Détail</th>
				<th>PU</th>
				<th>Total</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let price of getPrices()">
				<td>{{price.qte}}</td>
				<td>{{price.name}}</td>
				<td>{{((price.pu)? price.pu+'€' : '')}}</td>
				<td>{{price.total}}€</td>
			</tr>
			<tr>
				<td colspan="3"></td>
				<td>{{getTotal()}}€</td>
			</tr>
		</tbody>
	</table>
	<div class="buttons-container">	
		<sg-button title="Retour" [link]="rs.getRouterLink('grading.select-cards')" iconBefore="fa-solid fa-arrow-left"></sg-button>
		<sg-button title="Procéder au paiement" [link]="getNextStep()" [queryParams]="getQueryParam()" iconBefore="fa-solid fa-arrow-right"></sg-button>
	</div>
</div>
