import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ApiService } from './../services/api.service';
import { User } from './../models/user.model';

@Injectable({
	providedIn: 'root'
})
export class UserService{
	private user: User;

	constructor(private api: ApiService){}

	subscribe(user: User){
		return this.api.post<User>('/users/subscribe', user);
	}

	register(user: User){
		localStorage.setItem('user', JSON.stringify(user));
	}

	getUser(){
		if(!this.user)
			this.user=JSON.parse(localStorage.getItem('user'));
		return this.user;
	}

	isConnected(){
		return (this.getUser()!=null);
	}

	unlog(){
		localStorage.removeItem('user');
	}

	forgot(email: string){
		return this.api.get<any>('/users/forgot', {params: {email: email}});
	}

	static isValidPass(control: FormControl){
		const regexLow = /\w/g;
		const regexSpec = /\W/g;
		const regexNumber = /\d/g;
		const regexMinLength = /.{8,}/g;
		let match: boolean=true;

		if(!control.value.match(regexLow))
			match=false;
		if(!control.value.match(regexSpec))
			match=false;
		if(!control.value.match(regexNumber))
			match=false;
		if(!control.value.match(regexMinLength))
			match=false;

		return ((!match)? {passForm: true} : null);
	}
}