<section>
	<div class="header-container">
		<div class="title-container">
			<h1>Super-Grade</h1>
			<div class="sub-title">
				Le grading fiable, intelligent et avec style !
			</div>
			<div class="buttons">
				<sg-button text="Commander" [reverse]="true" ></sg-button>
				<sg-button text="Accessoires" [reverse]="true" ></sg-button>
			</div>
		</div>
		<aside>
			<img src="assets/img/skin/hero-1.png" alt="Slab Super-Grade"/>
		</aside>
	</div>
	<article class="slab">
		<header>
			<h2>Boitiers personnalisables</h2>
			Nous mettons à votre dispsition plus de 12 combinaisons de boitiers de protection<br/>
			pour rendre votre collection de cartes encore plus unique.
		</header>
		<sg-customs></sg-customs>
		<div class="slab-read-more">
			<sg-button [link]="rs.getRouterLink('slab')" text="En savoir +"></sg-button>
		</div>
	</article>
	<article class="pres-container">
		<div class="fiveD-container">
			<div>
				<sg-five-d-layers [width]="300"></sg-five-d-layers>
			</div>
			<div class="five5-content">
				<header class="text-content-pad">
					<h2>Une vision de votre carte en 5 dimensions</h2>
				</header>
				<div class="text-content-pad">
					Super-Grade, c'est plus que du <strong>grading de carte de collection</strong>. Nous vous proposons de vivre un expérience incroyable en disposant d'une vue en plusieurs dimensions et sous différentes typologies de vision. 
				</div>
				<!-- Vue réelle -->
				<div class="fiveD-criterias">
					<div class="fiveD-criteria">
						<sg-icon-reverse icon="fa-duotone fa-solar-system"></sg-icon-reverse>
						<span class="fiveD-title">Vue 3D réelle</span>
					</div>

					<!-- Vue IR -->
					<div class="fiveD-criteria">
						<sg-icon-reverse icon="fa-duotone fa-satellite-dish fa-rotate-90"></sg-icon-reverse>
						<span class="fiveD-title">Vue Infra Rouge</span>
					</div>

					<!-- Vue UV -->
					<div class="fiveD-criteria">
						<sg-icon-reverse icon="fa-duotone fa-eclipse fa-rotate-90"></sg-icon-reverse>
						<span class="fiveD-title">Vue Ultra Violet</span>
					</div>

					<!-- Vue topographique -->
					<div class="fiveD-criteria">
						<sg-icon-reverse icon="fa-duotone fa-radar"></sg-icon-reverse>
						<span class="fiveD-title">Vue topographique</span>
					</div>
				</div>
				<div class="text-content-pad">				
					<sg-button [link]="rs.getRouterLink('five-d')" text="En savoir +"></sg-button>
				</div>
			</div>
		</div>
	</article>
	<article class="pres-container">
		<div class="report-container">
			<div>
				<header class="text-content-pad">
					<h2>Rapport détaillé</h2>
				</header>
				<div class="text-content-pad text-content">
					<b>"Mais... je ne comprends pas ! J'étais certain que cette carte aurait la meilleur note...".</b> Plus de surprise ! Grâce à notre rapport détaillé, vous retrouverez tous les défauts qui caractérisent votre carte et qui affectent sa note.
				</div>

				<div class="criterias-container">
					<!-- explain -->
					<div class="criteria">
						<h3>Structure de la note</h3>
						<div class="criteria-content">
							<div>
								<sg-icon icon="fa-light fa-file-certificate"></sg-icon>
							</div>
							Retrouver tous les détails de la notation de votre carte.
						</div>
					</div>

					<!-- Surface -->
					<div class="criteria">
						<h3>Surface</h3>
						<div class="criteria-content">
							<sg-icon icon="fa-light fa-flatbread"></sg-icon>
							Localiser exactement tous les défauts qui affectent la note (micro-rayures, traces d'usure...).
						</div>
					</div>

					<!-- centring -->
					<div class="criteria">
						<h3>Centring</h3>
						<div class="criteria-content">
							<sg-icon icon="fa-light fa-arrows-to-circle"></sg-icon>
							Détails du calcul de centrage d'impression au 1000ème de miliètre près.
						</div>
					</div>

					<!-- Population -->
					<div class="criteria">
						<h3>Population</h3>
						<div class="criteria-content">
							<sg-icon icon="fa-light fa-chart-mixed"></sg-icon>
							Certifiez la rareté de votre note grâce aux informations de population.
						</div>
					</div>
				</div>

				<div class="button">
					<sg-button [link]="rs.getRouterLink('report')" text="En savoir+"></sg-button>
				</div>
			</div>
			<div class="report-img-container">
				<img src="assets/img/skin/report-3.png" alt="Reporting" class="report-img"/>
			</div>
		</div>
	</article>
	<article class="pres-container">
		<div class="ia-container">
			<div class="ia-img-container">
				<img src="assets/img/skin/ia.png" alt="learning machine" class="ia-img"/>
				<img src="assets/img/skin/ia-humain.png" alt="humain learning machine" class="humain-ia-img"/>
			</div>
			<div class="ia-text-container">
				<header class="text-content-pad">
					<h2>Grading assisté par Intelligence Artificielle</h2>
				</header>
				<div class="text-content-pad text-content">				
					Deux paires de yeux vallent mieux qu'une mais rien de plus extraordinaire que la combinaison de l'homme et de la machine. Chacun de nos experts sont assistés par des algorithmes d'Intelligence Artificielle sortant directement de laboratoire de recherche.
				</div>
				<ul class="sg-list aligned">
					<li>
						<i class="fa-duotone fa-check-double"></i>
						Technologie avancée provenant de laboratoire de recherche
					</li>
					<li>
						<i class="fa-duotone fa-check-double"></i>
						Totalement objectif
					</li>
					<li>
						<i class="fa-duotone fa-check-double"></i>
						Parfaitement reproductible et mesurable
					</li>
				</ul>
				<div class="text-content-pad">				
					<sg-button [link]="rs.getRouterLink('grading')" text="En savoir +"></sg-button>
				</div>
			</div>
		</div>
	</article>
	<article class="pres-container">
		<div class="">
			<div class="nft-header">
				<header>
					<h2>Liez votre carte à un token NFT</h2>
				</header>
				<div>
					Le NFT est un certificat de propriété unique qui atteste de la propriété et l'authneticité de votre carte. Un NFT en un certificat, qui contient toutes les informations relatives à votre carte.
				</div>
			</div>
			<div class="nft-criteria-container">
				<div class="nft-criteria">
					<sg-icon icon="fa-light fa-file-certificate"></sg-icon>
					<div class="text-content">
						<span class="nft-title">Cetificats d'authenticité : </span>un NFT constitue une preuve numérique de provenance et de propriété.
					</div>
				</div>

				<div class="nft-criteria">
					<sg-icon icon="fa-light fa-circle-1"></sg-icon>
					<div class="text-content">
						<span class="nft-title">Unicité : </span>un NFT est unique et un certificat infalsifiable vient authentifier cette unicité.
					</div>
				</div>

				<div class="nft-criteria">
					<sg-icon icon="fa-light fa-shield-keyhole"></sg-icon>
					<div class="text-content">
						<span class="nft-title">Sécurité : </span>un NFT créé ne peut être ni copié ni être falsifié.
					</div>
				</div>
			</div>
			<div class="nft-say-more">
				<sg-button [link]="rs.getRouterLink('nft')" text="En savoir +"></sg-button>
			</div>
			<img class="nft-img" src="assets/img/skin/nft-fived-reel-2.png" alt="Super-Grade NFT"/>
		</div>
	</article>
	<sg-start-grading></sg-start-grading>
</section>
