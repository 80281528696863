import { environment } from './../../environments/environment';

export class TypeCard{
	ref: string;
	name: string;
}

export class SetCard{
	public static readonly URL_SET_LOGO: string=environment.urlApp+'/img/set/logo';
	public static readonly URL_SET_SYMBOL: string=environment.urlApp+'/img/set/symbol';
	public static readonly DEFAULT_LOGO: string='assets/img/skin/default-set-logo.png';

	ident: string;
	name: string;
	symbol: string;
	logo: string;
	abbreviation: string;
	releaseDate: Date;
	nbCard: number;
	nbOfficialCard: number;
	translations: Array<SetTranslation>=[];
}

export class SetTranslation{
	public static readonly TS_NAME='name';

	name: string;
	text: string;
	lang: string;
}

export class Card{
	public static readonly URL_CARD_IMG: string=environment.urlApp+'/img/card';
	public static readonly DEFAULT_IMG: string='assets/img/skin/default-card.jpg';

	ident: string;
	name: string;
	numero: string;
	img: string;
	cardSet: string;
	categ: string;
	lastUpdate: string;
	features: Array<Feature>=[];
}

export class Feature{
	public static readonly FT_NAME="name";
	public static readonly FT_ATTACK_NAME="attack-name";
	public static readonly FT_ATTACK_TEXT="attack-text";
	public static readonly FT_TRAINER_EFFECT="trainer-text";
	public static readonly FT_ABILITIES="abilities";

	card: string;
	lang: string;
	name: string;
	text: string;
}