import { Injectable } from '@angular/core';
import { Card, SetCard } from './../models/card.model';
import { PositionSlab, FormSlab, MagnetSlab} from './../components/section/customs/slab';

@Injectable({
	providedIn: 'root'
})
export class CartService{
	private cards: CardCartOpt[];

	loadCards(){
		if(this.cards==null){
			let cards: CardCartOpt[]=JSON.parse(localStorage.getItem("cards"));
			if(cards==null)
				this.cards=[];
			else
				this.cards=cards;
		}
	}

	saveCards(){
		localStorage.setItem("cards", JSON.stringify(this.cards));
	}

	addCard(option: CardCartOpt){
		this.cards.push(option);
		this.saveCards();
	}

	getCards(){
		this.loadCards();
		return this.cards;
	}

	rmCard(index: number){
		this.cards.splice(index, 1);
		this.saveCards();
	}

	isEmpty(){
		return (this.getCards().length==0);
	}
}

export class CardCartOpt{
	qte: number=1;
	ident: string;
	card: string;
	numero: string;
	set: string;
	lang: string;
	value: number=1;
	slab: SlabCartOpt=new SlabCartOpt();
	fiveD: boolean=true;
	nft: boolean=true;

	clone(){
		return JSON.parse(JSON.stringify(this));
	}
}

export class SlabCartOpt{
	form: FormSlab='R';
	position: PositionSlab='H';
	magnet: MagnetSlab=true;
}