import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'sg-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.css']
})
export class StepComponent implements OnInit {
  @Input() name: string;
  @Input() icon: string;
  @Input() link: any[];
  @Input() active: boolean=false;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  isSelected(){
	return (this.link[0]==this.router.url);
  }

  getLink(){
  	if(this.active)
		return this.link;
	return this.router.url;
  }
}
