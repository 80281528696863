import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'sg-five-d-layers',
  templateUrl: './five-d-layers.component.html',
  styleUrls: ['./five-d-layers.component.css']
})
export class FiveDLayersComponent implements OnInit {
  @Input() width: number=300;
  constructor() { }

  ngOnInit(): void {
  }

  getWidth(){
  	return `${this.width}px`;
  }

}
