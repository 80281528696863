export class Price{
	public static GRADING_ITEM='grading';
	public static GUARANTEE_ITEM='guarantee';
	public static NFT_ITEM='nft';
	public static FIVED_ITEM='5D';
	public static SHIP_ITEM='ship';
	public static MAGNET_ITEM='magnet';

	ref: string;
	item: string;
	name: string;
	min: number;
	max: number;
	value: number;
}