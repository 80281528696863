<div [ngClass]="{'menu-container': true, transiant: transiant}">
	<div [ngClass]="{'logo-container': true, transiant: transiant}">
		<img *ngIf="!transiant" class="logo" src="assets/img/icon/logo-unique.png" alt="Super-Grade"/>
		<img *ngIf="transiant" class="logo" src="assets/img/icon/logo-white-mini.png" alt="Super-Grade"/>
		<div class="logo-content">
			<span class="logo-title">Super-Grade</span><br/>
			Smart Grading
		</div>
	</div>
	<div class="right-container">
		<nav class="nav-pc">
			<ul>
				<li>
					<a href="" title="Accueil" [routerLink]="rs.getRouterLink('home')" routerLinkActive="selected">Super-Grade</a>
				</li>
				<li>
					<a href="" title="Nos services" [routerLink]="rs.getRouterLink('grading')" routerLinkActive="selected">Nos services</a>
					<ul>
						<li><a href="" title="Grading" [routerLink]="rs.getRouterLink('grading')" routerLinkActive="selected">Grading</a></li>
						<li><a href="" title="Nos boitiers" [routerLink]="rs.getRouterLink('slab')" routerLinkActive="selected">Nos boitiers</a></li>
						<li><a href="" title="Le report" [routerLink]="rs.getRouterLink('report')" routerLinkActive="selected">Le report</a></li>
						<li><a href="" title="Vue 5D" [routerLink]="rs.getRouterLink('five-d')" routerLinkActive="selected">Vue 5D</a></li>
						<li><a href="" title="NFT" [routerLink]="rs.getRouterLink('nft')" routerLinkActive="selected">NFT</a></li>
					</ul>
				</li>
				<li>
					<a href="" title="Shop" [routerLink]="rs.getRouterLink('default')" routerLinkActive="selected">Shop</a>
				</li>
				<li>
					<a href="" title="Statistiques" [routerLink]="rs.getRouterLink('default')" routerLinkActive="selected">Population</a>
				</li>
			</ul>
		</nav>
		<div class="social">
			<a href="" class="fa-stack" title="Facebook" target="_blank">
			    <i class="fa-thin fa-circle fa-stack-2x"></i>
			    <i class="fa-brands fa-facebook-f fa-stack-1x"></i>
			</a>
			<a href="" class="fa-stack" title="Instagram" target="_blank">
			    <i class="fa-thin fa-circle fa-stack-2x"></i>
			    <i class="fa-brands fa-instagram fa-stack-1x"></i>
			</a>
			<a href="" [routerLink]="rs.getRouterLink('grading.check-cart')" title="Panier" class="fa-stack">
			    <i class="fa-thin fa-circle fa-stack-2x"></i>
			    <i class="fa-light fa-basket-shopping-simple fa-stack-1x"></i>
			</a>
			<a href="" [routerLink]="rs.getRouterLink('login')" routerLinkActive="selected" title="Login" class="fa-stack">
			    <i class="fa-thin fa-circle fa-stack-2x"></i>
			    <i class="fa-light fa-user-bounty-hunter fa-stack-1x"></i>
			</a>
		</div>
	</div>
	<div class="right-content-pad">
		<a href="#" class="toggle-menu" onclick="return false;" (click)="toggleMenu()" title="open menu">
			<i class="fa-duotone fa-bars-sort"></i>
		</a>
	</div>
	<nav class="menu-list" (click)="toggleMenu()" [ngClass]="{open: isOpen}">
		<ul>
			<li>
				<a href="" title="Accueil" [routerLink]="rs.getRouterLink('home')" routerLinkActive="selected">Super-Grade</a>
			</li>
			<li>
				<a href="" title="Nos services" [routerLink]="rs.getRouterLink('grading')" routerLinkActive="selected">Nos services</a>
				<ul>
					<li><a href="" title="Grading" [routerLink]="rs.getRouterLink('grading')" routerLinkActive="selected">Grading</a></li>
					<li><a href="" title="Nos boitiers" [routerLink]="rs.getRouterLink('slab')" routerLinkActive="selected">Nos boitiers</a></li>
					<li><a href="" title="Le report" [routerLink]="rs.getRouterLink('report')" routerLinkActive="selected">Le report</a></li>
					<li><a href="" title="Vue 5D" [routerLink]="rs.getRouterLink('five-d')" routerLinkActive="selected">Vue 5D</a></li>
					<li><a href="" title="NFT" [routerLink]="rs.getRouterLink('nft')" routerLinkActive="selected">NFT</a></li>
				</ul>
			</li>
			<li>
				<a href="" title="Shop" [routerLink]="rs.getRouterLink('default')" routerLinkActive="selected">Shop</a>
			</li>
			<li>
				<a href="" title="Statistiques" [routerLink]="rs.getRouterLink('default')" routerLinkActive="selected">Population</a>
			</li>
			<li class="link-rs">
				<a href="" class="fa-stack" title="Facebook" target="_blank">
				    <i class="fa-thin fa-circle fa-stack-2x"></i>
				    <i class="fa-brands fa-facebook-f fa-stack-1x"></i>
				</a>
				<a href="" class="fa-stack" title="Instagram" target="_blank">
				    <i class="fa-thin fa-circle fa-stack-2x"></i>
				    <i class="fa-brands fa-instagram fa-stack-1x"></i>
				</a>
				<a href="" [routerLink]="rs.getRouterLink('grading.check')" title="Panier" class="fa-stack">
				    <i class="fa-thin fa-circle fa-stack-2x"></i>
				    <i class="fa-light fa-basket-shopping-simple fa-stack-1x"></i>
				</a>
				<a href="" [routerLink]="rs.getRouterLink('login')" routerLinkActive="selected" title="Login" class="fa-stack">
				    <i class="fa-thin fa-circle fa-stack-2x"></i>
				    <i class="fa-light fa-user-bounty-hunter fa-stack-1x"></i>
				</a>
			</li>
		</ul>
	</nav>
</div>