import { Component, OnInit, Input } from '@angular/core';
import { FormSlab, PositionSlab,  MagnetSlab} from './slab';

@Component({
  selector: 'sg-customs',
  templateUrl: './customs.component.html',
  styleUrls: ['./customs.component.css']
})
export class CustomsComponent implements OnInit {
  @Input() form: FormSlab='R';
  @Input() position: PositionSlab='H';
  @Input() magnet: MagnetSlab=false;
  @Input() isRotate: boolean=true;

  constructor() { }

  ngOnInit(): void {
  }

  setOptions(){
    switch(this.form){
      case 'H':
        this.position='G';
        break;
      case 'C':
        this.position='G';
        break;
      case 'R':
        this.position='H';
        break;
    }
  }

  getImage(){
  	return "assets/img/slab/rendu-20.jpg";
  }

  getImageBack(){
  	return "assets/img/slab/rendu-20-back.jpg";
  }

}
