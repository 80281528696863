<article class="pres-alt">
	<div class="pres-container">
		<header>
			<h2>Commandez vos services de grading</h2>
		</header>
		<div class="start-gradign-content">
			Nos services vous interessent et vous désirez vous lancer ? <br/>
			N'attendez plus, sublimez votre collection de cartes en commandant vos services de grading, de carte 3D et NFT... en quelques cliques.<br/>
		</div>
		<sg-button text="Commander" [link]="rs.getRouterLink('grading.select-cards')"></sg-button>
	</div>
</article>
