<div class="search-container" [isLoading]="loading">
	<h2 *ngIf="title!=null">{{title}}</h2>
	<div class="criterias-container" [formGroup]="search">
		<select class="inputFormat" (change)="initActive()" formControlName="lang">
			<option value="">Langue</option>
			<option *ngFor="let lang of langs" value="{{lang.ref}}">{{lang.name}}</option>
		</select>
		<select class="inputFormat" (change)="initActive()" formControlName="type">
			<option value="">Type de carte</option>
			<option *ngFor="let type of types" value="{{type.ref}}">{{type.name}}</option>
		</select>
		<input type="text" class="inputFormat" placeholder="Rechercher..." formControlName="search"/>
		<!-- <sg-button [styles]="{padding: '5px 30px'}" iconBefore="fa-regular fa-magnifying-glass"></sg-button> -->
	</div>
	<sg-card-list [cards]="cards" [lang]="search.get('lang').value" [type]="search.get('type').value" [page]="page+1" [pristine]="search.get('search').pristine" (changePage)="changePage($event)" (addCard)="addCard($event)" (addCutomCard)="addCustomCard($event)"></sg-card-list>
</div>