<!-- option de grading : boitier, 3D, NFT -->
<div class="bloc-container">
	<sg-option-grading [option]="option"></sg-option-grading>
</div>

<!-- recherche de carte -->
<div class="bloc-container">
	<sg-search-card (addCard)="addCard($event)" [option]="option" title="Ajouter une carte"></sg-search-card>
</div>

<!-- Liste des cartes choisies -->
<div class="bloc-container">
	<sg-card-tab></sg-card-tab>
</div>