import { Component, OnInit, Input } from '@angular/core';
import { FormSlab, MagnetSlab, PositionSlab, NFTSlab, FiveDSlab } from './../../section/customs/slab';
import { CardCartOpt } from './../../../services/cart.service';

@Component({
  selector: 'sg-option-grading',
  templateUrl: './option-grading.component.html',
  styleUrls: ['./option-grading.component.css']
})
export class OptionGradingComponent implements OnInit {
  @Input() option: CardCartOpt;

  constructor() {  }

  ngOnInit(): void {
  }

  setOptions(){
    switch(this.option.slab.form){
      case 'H':
        this.option.slab.position='G';
        break;
      case 'C':
        this.option.slab.position='G';
        break;
      case 'R':
        this.option.slab.position='H';
        break;
    }
  }
}
